import React, {useState} from 'react'
import * as PropTypes from 'prop-types'
import {
  DialogTitle,
  DialogActions,
  Button, DialogContent, Switch, FormControlLabel
} from '@material-ui/core'

const SettingsDialog = ({close, title, headersSort, properties: {headers, initHeaders}}) => {
  const initValues = initHeaders.reduce((map, e) => {
    map[e] = headers.indexOf(e) > -1
    return map
  }, {})

  const [values, setValues] = useState(initValues)
  return (
    <React.Fragment>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {Object.keys(values).map((key, i) => {
          return (
            <FormControlLabel
              key={key}
              color="primary"
              style={{display: 'flex'}}
              control={
                <Switch checked={values[key]}
                        onChange={e => {
                          setValues(prevState => ({...prevState, [key]: e.target.checked}))
                        }}/>
              }
							label={key}
							labelPlacement="end"
						/>)
        })}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => {
					const v = Object.keys(values).filter(e => values[e])
          v.sort(headersSort)
					close({refresh: false, headers: v})
				}} variant={'contained'} size="small">
          Ok
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

SettingsDialog.propTypes = {
  title: PropTypes.string,
	properties: PropTypes.object,
  headersSort: PropTypes.func,
  close: PropTypes.func
}

export default SettingsDialog