/**
 * Created by davidcarlson on 2017-01-03.
 */

import axios from 'axios'
import {BASE_URL} from '../app/constants'
import * as api from './userApi'

import history from '../history'

export const GET_USER_DETAILS = 'GET_USER_DETAILS'

export function getUserDetails(user, token) {
	return (dispatch) => {
		dispatch({
			type: 'IS_LOADING',
			isLoading: true
		})

		api.getUser(token).then((userResp) => {
			dispatch(saveUser(userResp.data.Properties.LoggedInUser))
			api.getRoles(userResp.data.Links, token).then(res => {
				const roleProps = res.data.Properties
				if (roleProps.Total > 0) {
					dispatch(saveRole(roleProps.Elements))
				} else {
					history.push('/login')

					dispatch({
						type: 'AUTH_ERROR',
						error: 'not authorized'
					})
				}
				dispatch({
					type: 'IS_LOADING',
					isLoading: false
				})
			}).catch((err) => {
				dispatch({
					type: 'NOTIFY_ERROR',
					error: err.toString()
				})

				dispatch({
					type: 'IS_LOADING',
					isLoading: false
				})
			})
		}).catch((err) => {
			dispatch({
				type: 'NOTIFY_ERROR',
				error: err.toString()
			})

			dispatch({
				type: 'IS_LOADING',
				isLoading: false
			})
		})
	}
}

export const GET_USER = 'GET_USER'

export function getUser(token) {
	return (dispatch) => {
		var config = {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Accept': 'application/json'
			}
		}
		axios.get(BASE_URL, config)
			.then((resp) => {
				dispatch(saveUser(resp.data.Properties.LoggedInUser))
				dispatch(getRoles(resp.data.Links, token))
				dispatch(getDetails(resp.data.Links, token))
			})
			.catch((err) => {
				dispatch({
					type: 'NOTIFY_ERROR',
					error: err.toString()
				})
			})
	}
}

export const SAVE_USER = 'SAVE_USER'

export function saveUser(user) {
	return {
		type: SAVE_USER,
		user
	}
}

export const GET_ROLES = 'GET_ROLES'

export function getRoles(links, token) {
	return (dispatch) => {
		for (var i = 0; i < links.length; i++) {
			if (links[i].Rel === 'roles') {

				var config = {
					headers: {
						'Authorization': 'Bearer ' + token,
						'Accept': 'application/json'
					}
				}

				axios.get(links[i].URL, config)
					.then((resp) => {
						dispatch(saveRole(resp.data.Properties.Elements))
					})
					.catch((err) => {
						dispatch({
							type: 'NOTIFY_ERROR',
							error: err.toString()
						})
					})

				break
			}
		}
	}
}

export const SAVE_ROLE = 'SAVE_ROLE'

export function saveRole(role) {
	return {
		type: SAVE_ROLE,
		role
	}
}

export const GET_DETAILS = 'GET_DETAILS'

export function getDetails(token, userId) {
	return (dispatch) => {
		api.getDetails(token, userId)
			.then((resp) => {
				dispatch(saveDetails(resp.data.Properties.Elements[0].Properties))
			})
			.catch((err) => {
				dispatch({
					type: 'NOTIFY_ERROR',
					error: err.toString()
				})
			})
	}
}

export const CREATE_DETAILS = 'CREATE_DETAILS'

export function createDetails(detailsData) {
	return (dispatch, getState) => {
		let currState = getState()

		api.createDetails(currState.login.user.Fd, currState.organization.org.Properties.ID, currState.user.user.ID, detailsData)
			.then((resp) => {
				dispatch({
					type: 'IS_LOADING',
					isLoading: true
				})

				if (detailsData.Image) {
				}

				dispatch(saveDetails(resp.data))

				dispatch({
					type: 'IS_LOADING',
					isLoading: false
				})
			})
			.catch((err) => {
				dispatch({
					type: 'NOTIFY_ERROR',
					error: err.toString()
				})
			})
	}
}

export const UPDATE_DETAILS = 'UPDATE_DETAILS'

export function updateDetails(detailsData) {
	return (dispatch, getState) => {
		let currState = getState()

		dispatch({
			type: 'IS_LOADING',
			isLoading: true
		})

		api.updateDetails(currState.login.user.Fd, currState.organization.org.Properties.ID, currState.user.user.ID, currState.user.details.ID, detailsData)
			.then((resp) => {
				dispatch(saveDetails(resp.data.Properties))

				dispatch({
					type: 'IS_LOADING',
					isLoading: false
				})

				if (detailsData.Image) {
				}

			})
			.catch((err) => {
				dispatch({
					type: 'NOTIFY_ERROR',
					error: err.toString()
				})
			})
	}
}

export const SAVE_DETAILS = 'SAVE_DETAILS'

export function saveDetails(userDetails) {
	return {
		type: SAVE_DETAILS,
		userDetails
	}
}