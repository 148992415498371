/**
 * Created by davidcarlson on 2017-01-16.
 */
import {grey,} from '@material-ui/core/colors'

const grey100 = grey[100]
const grey300 = grey[300]
const grey500 = grey[500]
const grey700 = grey[700]
const fullWhite = '#fff'
const fullBlack = '#000'

export const teoriapparColors = {
	green: '#86C77C',
}

export default {
	typography: {
		fontFamily: [
			'Museo-Sans-500',
			'Museo-Sans-700',
			'Museo-Sans-900',
		].join(','),
	},
	palette: {
		primary1Color: '#4da944',
		primary2Color: '#54af4b',
		primary3Color: '#ff86d05f',
		accent1Color: '#4da944',
		accent2Color: '#54af4b',
		accent3Color: '#ff86d05f',
		textColor: fullBlack,
		secondaryTextColor: fullBlack,
		alternateTextColor: '#54af4b',
		canvasColor: grey100,
		borderColor: grey700,
		disabledColor: fullBlack,
		pickerHeaderColor: fullBlack,
		clockCircleColor: fullBlack
	},
	/*overrides: {
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: teoriapparColors.green,
				color: teoriapparColors.green,
				boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
			},
		},
	},*/
	badge: {
		secondaryColor: '#54af4b',
		secondaryTextColor: fullWhite,
	},
	checkbox: {
		checkedColor: '#4da944',
	},
	drawer: {
		color: '#272c36',
	},
	flatButton: {
		disabledTextColor: fullWhite,
		textColor: fullWhite,
	},
	radioButton: {
		checkedColor: '#4da944',
	},
	raisedButton: {
		primaryTextColor: fullWhite,
		secondaryColor: fullWhite,
		secondaryTextColor: '#4da944',
		disabledColor: grey700,
		disabledTextColor: fullWhite,
	},
	snackbar: {
		textColor: fullWhite,
		actionColor: '#54af4b',
	},
	tableHeader: {
		borderColor: grey300,
	},
	tableRow: {
		selectedColor: fullBlack,
		borderColor: grey300,
	},
	tabs: {
		backgroundColor: grey500,
		textColor: fullWhite,
		selectedTextColor: fullWhite,
	},
	tableHeaderColumn: {
		textColor: fullBlack,
		height: 40,
	},
	datePicker: {
		headerColor: '#272c36',
		textColor: fullWhite,
		accentColor: '#54af4b',
		selectColor: '#54af4b',
		selectTextColor: fullWhite,
	},
	timePicker: {
		headerColor: '#272c36',
		textColor: fullWhite,
		accentColor: '#54af4b',
		selectColor: '#54af4b',
		selectTextColor: fullWhite,
	},
	textField: {
		textColor: fullBlack,
		floatingLabelColor: grey500,
		hintColor: grey500,
		focusColor: '#4da944',
	},
	toggle: {
		thumbOnColor: '#54af4b',
		thumbOffColor: grey500,
		trackOnColor: '#54af4b',
		trackOffColor: grey500,
	},
	toolbar: {
		color: fullWhite,
		backgroundColor: '#525c6f'
	}
}