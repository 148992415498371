import React, {Component} from 'react'
import * as PropTypes from 'prop-types'
import {debounce} from 'throttle-debounce'
import {TextField} from '@material-ui/core'
import credentials from '../../firebase/credentials'
import {bindActionCreators} from 'redux'
import * as clientActions from '../../client/actions'
import {connect} from 'react-redux'

const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')

class VersionTextField extends Component {
	constructor(props) {
		super(props)
		this.state = {
			inputString: '',
		}

		this.updateVersion = debounce(1000, this.updateVersion)
	}

	componentDidMount() {
		const {doRequest, url, user: {user: {Token}}} = this.props
		if (!url) return
		const link = {URL: `https://${credentials.storageBucket}${url}`, Method: 'GET'}
		doRequest(Token, link).then(res => {
			if (!res) return
			const version = !res.data.Properties.Version ? 1.0 : res.data.Properties.Version
			const inputString = version.toString()
			this.setState({inputString, version})
		})
	}

	updateVersion = () => {
		const {inputString} = this.state
		if (inputString === '') {
			return
		}
		const ok = floatRegExp.test(inputString)
		if (!ok) {
			return
		}
		const f = parseFloat(inputString)
		const {doRequest, url, user: {user: {Token}}} = this.props
		if (!url) return
		const link = {URL: `https://${credentials.storageBucket}${url}`, Method: 'PUT'}
		doRequest(Token, link, '', 0, '', {Version: f}).then(res => {
			if (!res) return
			this.setState({version: res.data.Properties.Version})
		})
	}

	onChange = e => {
		const {value} = e.target
		const ok = floatRegExp.test(value)
		if (value === '' || ok) {
			this.setState({inputString: e.target.value})
			if (ok) {
				this.updateVersion()
			}
		}
	}

	onBlur = () => {
		if (this.state.inputString === '') {
			this.setState(state =>({inputString: state.version.toString()}))
		}
	}

	render() {
		const {inputString} = this.state
		return (
			<TextField
				variant={'outlined'}
				margin={'dense'}
				label={'Version'}
				onBlur={this.onBlur}
				onChange={this.onChange}
				value={inputString}
			/>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({...clientActions}, dispatch)
}

function mapStateToProps(state, routeProps) {
	return {
		routeProps,
		user: state.user,
	}
}

VersionTextField.protoTypes = {
	doRequest: PropTypes.func,
	url: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionTextField)