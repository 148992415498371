/**
 * Created by davidcarlson on 2017-01-03.
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import helpers from '../helpers'
import {debounce} from 'throttle-debounce'
import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'
import {Bar} from 'react-chartjs-2'
import fileDownload from 'js-file-download'
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'

import TableToolbar from '../list/TableToolbar'

const DATE_FORMAT = 'dd D MMM YYYY HH:mm'

class Installations extends Component {
	constructor(props) {
		super(props)
		const componentName = this.getComponentName()
		this.state = {
			rescModalOpen: false,
			modalHeader: undefined,
			modalBody: undefined,
			link: helpers.getURL(props.selectedApp, componentName),
			create: undefined,
			next: undefined,
			elements: undefined,
			search: '',
			refundActive: false,
			csv: false,
			sortOpt: 0,
			limitOpt: 0,
			totalAmount: 0,
			convertedAmount: 0,
			fullAmount: 0,
			freeAmount: 0,
			selectedRow: -1,
			from: moment().add(-4, 'weeks').toISOString(),
			until: moment().add(1, 'day').toISOString(),
			componentName: componentName,
			statistics: {
				labels: [],
				datasets: [],
			},
			filters: {},
		}
		this.callClient = debounce(1000, this.callClient)
	}

	getComponentName() {
		const array = this.props.routeProps.location.pathname.split('/')
		return array[array.length - 1]
	}

	componentDidMount() {
		this.callClient()
	}

	handleChangeFilter = f => {
		this.setState({filters: f})
		this.callClient()
	}

	handleSelectFrom = date => {
		this.setState({from: moment(date).toISOString()})
		this.callClient()
	}

	handleSelectUntil = date => {
		this.setState({until: moment(date).add(1, 'day').toISOString()})
		this.callClient()
	}

	handleDownloadCSV = () => {
		this.setState({csv: true})
		this.callClient()
	}

	setLinks = data => {
		if (data) {
			for (let link of data.Links) {
				this.setState({[link.Rel]: link})
			}
		}
	}

	callClient = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		if (!this.state.link) {
			this.setState({search: '', link: helpers.getURL(this.props.selectedApp, this.state.componentName)})
			this.callClient(token)
		}
		let f = this.state.filters
		f.from = this.state.from
		f.until = this.state.until
		f.csv = this.state.csv
		if (!this.state.link) {
			return
		}
		this.props.doRequest(token, this.state.link, undefined, undefined, undefined, undefined, f).then(res => {
			if (res && res.data) {
				if (this.state.csv) {
					fileDownload(res.data, `installations_${moment(this.state.from).format('YYYY-MM-DD')}_${moment(this.state.until).format('YYYY-MM-DD')}.csv`)
					this.setState({csv: false})
				} else {
					this.setLinks(res.data)
					this.setState({elements: res.data.Properties.Elements})
				}
				this.setStatistics('YYYY-MM-DD')
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	getColor = status => {
		switch (status) {
			case 'full':
				return '#5498e9'
			case 'free':
				return '#ecdb4f'
			default:
				return '#7AD171'
		}
	}

	listContainer(elements) {
		return <Table ref="table"
		              onChange={this.selectPayment}
		>
			<TableHead>
				<TableRow>
					<TableCell>FreeVersion</TableCell>
					<TableCell>FullVersion</TableCell>
					<TableCell>UserID</TableCell>
					<TableCell>DeviceType</TableCell>
					<TableCell>AppVersion</TableCell>
					<TableCell>FreeVersionAt</TableCell>
					<TableCell>FullVersionAt</TableCell>
					<TableCell>CreatedAt</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{
					elements.map(({Properties: {FreeVersion, FullVersion, UserID, DeviceType, AppVersion, FreeVersionAt, FullVersionAt, CreatedAt}}, index) =>
						<TableRow style={{cursor: 'pointer'}} key={index}>
							<TableCell children={`${FreeVersion}`}/>
							<TableCell children={`${FullVersion}`}/>
							<TableCell children={UserID}/>
							<TableCell children={DeviceType}/>
							<TableCell children={AppVersion}/>
							<TableCell
								children={moment(FreeVersionAt).year() > 1 ? `${moment(FreeVersionAt).format(DATE_FORMAT)}` : '-'}/>
							<TableCell
								children={moment(FullVersionAt).year() > 1 ? `${moment(FullVersionAt).format(DATE_FORMAT)}` : '-'}/>
							<TableCell
								children={CreatedAt && moment(CreatedAt).year() > 1 ? `${moment(CreatedAt).format(DATE_FORMAT)}` : '-'}/>
						</TableRow>
					)}
			</TableBody>
		</Table>
	}

	getStatus = prop => {
		if (prop.FreeVersion === true && prop.FullVersion === true) {
			return 'converted'
		}
		if (prop.FullVersion === true) {
			return 'full'
		}
		if (prop.FreeVersion === true) {
			return 'free'
		}
	}

	setStatistics = dateFormat => {
		let statuses = {}
		let obj = {}
		let totalAmount = this.state.elements.length
		let fullAmount = 0
		let freeAmount = 0
		let convertedAmount = 0
		for (let i = 0; i < this.state.elements.length; i++) {
			const status = this.getStatus(this.state.elements[i].Properties)

			const groupTime = moment(this.state.elements[i].Properties.CreatedAt).format(dateFormat)
			if (obj[groupTime + '' + status]) {
				obj[groupTime + '' + status] = obj[groupTime + '' + status] + 1
			} else {
				obj[groupTime + '' + status] = 1
			}
			if (this.state.elements[i].Properties.FullVersion) {
				fullAmount++
			}
			if (this.state.elements[i].Properties.FreeVersion) {
				freeAmount++
			}
			if (this.state.elements[i].Properties.FreeVersion && this.state.elements[i].Properties.FullVersion) {
				convertedAmount++
			}
			if (statuses[status]) {
				statuses[status].push(obj)
			} else {
				statuses[status] = []
				statuses[status].push(obj)
			}
		}
		const timeSlots = this.getTimeSlots(this.state.from, this.state.until, dateFormat)
		let datasets = []
		let keys = Object.keys(statuses)
		for (let i = 0; i < keys.length; i++) {
			let resourceList = statuses[keys[i]]
			let usageData = {}
			for (let j = 0; j < timeSlots.length; j++) {
				let temp = true
				for (let k = 0; k < resourceList.length; k++) {
					if (resourceList[k][timeSlots[j] + '' + keys[i]]) {
						usageData[timeSlots[j]] = resourceList[k][timeSlots[j] + '' + keys[i]]
						temp = false
					}
				}
				if (temp) {
					usageData[timeSlots[j]] = 0
				}
			}
			const color = this.getColor(keys[i])
			datasets.push({
				label: keys[i],
				fill: false,
				lineTension: 1,
				backgroundColor: color,
				borderColor: color,
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0,
				borderJoinStyle: 'miter',
				pointBorderColor: color,
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: color,
				pointHoverBorderColor: 'rgba(220,220,220,1)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: Object.keys(usageData).map(e => {
					return usageData[e]
				}),
				spanGaps: false
			})
		}
		this.setState({
			statistics: {labels: timeSlots, datasets: datasets},
			totalAmount,
			fullAmount,
			freeAmount,
			convertedAmount
		})
	}

	getTimeSlots = (start, end, dateFormat) => {
		let timeSlots = []
		let format = 'days'
		switch (dateFormat) {
			case 'YYYY-MM-DD HH:mm':
				format = 'minute'
				break
			case 'YYYY-MM-DD':
				format = 'days'
				break
			case 'YYYY-MM-DD HH':
				format = 'hours'
				break
			default:
				format = 'hours'
				break
		}
		let currentTime = moment(start).format(dateFormat)
		while (moment(currentTime).isBefore(moment(end).format(dateFormat))) {
			timeSlots.push(currentTime)
			currentTime = moment(currentTime).add(1, format).format(dateFormat)
		}
		return timeSlots
	}

	render() {
		return (
			<div style={{
				height: '100%',
				width: '100%',
			}}>
				<div>
					<div style={{padding: 10}}>
						<TableToolbar
							csvActive={this.state.elements && this.state.elements.length > 0}
							handleChangeFromDate={this.handleSelectFrom}
							handleChangeUntilDate={this.handleSelectUntil}
							handleDownloadCSV={this.handleDownloadCSV}
							handleChangeFilter={this.handleChangeFilter}
							filterOpts={[{f: 'deviceType', v: 'android'}, {f: 'deviceType', v: 'ios'}]}
							//TODO handle openModalDialog
							openModalDialog={null}
						/>
						{!!this.state.elements ? !!this.state.elements.length > 0 ? <div>
								<div style={{maxHeight: 350}}>
									<Bar
										data={this.state.statistics}
										width={100}
										height={350}
										options={{
											maintainAspectRatio: false,
											animation: false,
										}}
									/>
								</div>
								<hr/>
								<div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
									<div style={{display: 'flex', flexDirection: 'column', marginRight: 30}}>
	                    <span><label>Total Amount:</label>&nbsp;<b
		                    style={{fontSize: '1.3em'}}>{`${this.state.totalAmount}`}</b></span>
										<span><label>Converted Amount:</label>&nbsp;<b
											style={{fontSize: '1.3em'}}>{`${this.state.convertedAmount}`}</b></span>
									</div>
									<div style={{display: 'flex', flexDirection: 'column'}}>
	                    <span><label>Free Amount:</label>&nbsp;<b
		                    style={{fontSize: '1.3em'}}>{`${this.state.freeAmount}`}</b></span>
										<span><label>Full Amount:</label>&nbsp;<b
											style={{fontSize: '1.3em'}}>{`${this.state.fullAmount}`}</b></span>
									</div>
								</div>
								<hr/>
							</div>
							: null
							: null
						}
					</div>

					{!!this.state.elements ? !!this.state.elements.length > 0 ? this.listContainer(this.state.elements)
						: <div
							style={{
								textTransform: 'uppercase',
								height: 100,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '40px 0px'
							}}
						>
							<h4>{`No ${this.state.componentName} found`}</h4>
						</div>

						: null
					}
				</div>
			</div>
		)
	}
}

Installations.propTypes = {
	user: PropTypes.object.isRequired,
	selectedApp: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	routeProps: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	saveUser: PropTypes.func,
}

export default Installations
