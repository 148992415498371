import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Divider, List, ListItem, ListItemText, Paper} from '@material-ui/core'
import FlagIconFactory from 'react-flag-icon-css'

const FlagIcon = FlagIconFactory(React, {useCssModules: false})

class Apps extends Component {
	constructor(props) {
		super(props)
		this.state = {
			appGroups: []
		}
	}

	componentDidMount() {
		this.groupAppsByCountry()
	}

	groupAppsByCountry() {
		const appGroups = this.props.apps.reduce((group, app) => {
			const id = app.Properties.CountryID
			if (!group[id]) {
				group[id] = []
			}
			group[id].push(app)
			return group
		}, {})

		this.setState({
			appGroups: appGroups
		})
	}

	hasApps() {
		return this.props.apps.length > 0
	}

	render() {
		if (this.hasApps()) {
			return (
				<div style={styles.container}>
					{Object.keys(this.state.appGroups).map(group => this.renderGroup(group, this.state.appGroups[group]))}
				</div>
			)
		} else {
			return (
				<div style={styles.container}>
					<div style={styles.noApps}>
						<h4>No apps created yet</h4>
					</div>
				</div>
			)
		}
	}

	renderGroup(key, items) {
		return (
			<div key={key}>
				<div>{key}</div>
				<Paper style={styles.appGroup}>
					<List>
						{items.map((app, index) => this.renderRow(app, this.isActiveApp(app), index === items.length - 1))}
					</List>
				</Paper>
			</div>
		)
	}

	isActiveApp(app) {
		return this.props.selectedApp ? (app.Properties.Name === this.props.selectedApp.Properties.Name) : false
	}

	renderRow(app, isActive, isLastRow) {
		return (
			<div style={isActive ? styles.activeRow : {}} key={app.Properties.Name}>
				<ListItem onClick={() => this.props.saveApp(app)} button>
					<FlagIcon code={app.Properties.CountryID !== 'SWE' ? app.Properties.CountryID.toLowerCase() : 'se'}
					          squared={false}/>
					<ListItemText style={{paddingLeft: 10}} primary={app.Properties.Name}/>
				</ListItem>
				{!isLastRow && <Divider/>}
			</div>
		)
	}
}

const styles = {
	container: {
		display: 'inline-block',
		height: '100%',
		width: '50%',
		minWidth: 560,
		maxWidth: 700,
		marginBottom: 10,
		alignSelf: 'center',
		marginTop: '20px'
	},
	appGroup: {
		marginBottom: '40px'
	},
	noApps: {
		textTransform: 'uppercase',
		height: 100,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '40px 0px'
	},
	activeRow: {
		backgroundColor: 'rgb(230,230,230)'

	}
}

Apps.propTypes = {
	apps: PropTypes.array,
	match: PropTypes.object.isRequired,
	routeProps: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	saveApp: PropTypes.func,
}

export default Apps