import React from 'react'
import PropTypes from 'prop-types'
import CancelIcon from '@material-ui/icons/Clear'
import PhotoIcon from '@material-ui/icons/AddAPhoto'

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Slide} from '@material-ui/core'
import SourceComponent from '../sources/Sources'
import {grey,} from '@material-ui/core/colors'

class SourceDialog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const {open, onClose, QuestionImage, Sources, handleFileChange, removeSource, onSourceClick} = this.props
		return (
			<Dialog
				maxWidth={'md'}
				TransitionComponent={Slide}
				open={open} disableAutoFocus
				scroll={'body'}
			>
				<DialogTitle>{Sources && Sources.length > 0 ? Sources[0].Name : 'New source'}</DialogTitle>
				<DialogContent>
					<div style={{width: '100%', height: 500}}>
						{((Sources && Sources.length > 0) || QuestionImage) &&
						<div onClick={removeSource} style={{
							display: 'flex',
							position: 'absolute',
							backgroundColor: '#ddddddd6',
							borderRadius: '0 0 4px 0',
							cursor: 'pointer',
						}}>
							<CancelIcon
								style={{color: grey[700]}}
							/>
						</div>}
						<Paper
							onClick={() => this.refs.fileinput.click()}
							style={{
								cursor: 'pointer',
								width: '100%',
								height: '100%',
							}}
						>
							<div style={{
								height: '100%',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundImage: QuestionImage ? 'url(' + QuestionImage + ')' : Sources && Sources.length > 0 ? 'url(' + Sources[0].URL + ')' : null,
								backgroundSize: 'cover',
								backgroundPosition: 'center center',
								backgroundRepeat: 'no-repeat'
							}}>
								<PhotoIcon
									style={{
										color: grey[400],
										width: 60,
										height: 60,
									}}/>
							</div>
							<input
								type="file"
								id="upload"
								ref="fileinput"
								accept="image/x-png,image/jpeg"
								className="hidden"
								onChange={handleFileChange}
							/>

						</Paper>
					</div>
					<SourceComponent onListItemClick={onSourceClick} maxWidth={'100%'} {...this.props}/>
					<DialogActions>
						<Button color={'primary'} variant={'contained'} onClick={onClose}>Close</Button>
					</DialogActions>
				</DialogContent>
			</Dialog>
		)
	}
}

SourceDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	handleFileChange: PropTypes.func,
	onSourceClick: PropTypes.func,
	removeSource: PropTypes.func,
	QuestionImage: PropTypes.string,
	Sources: PropTypes.array,
}

export default SourceDialog