/**
 * Created by davidcarlson on 2017-01-03.
 */

const initialState = {
	selectedApp: undefined,
}

function app(state = initialState, action) {
	switch (action.type) {
		default:
			return state
	}
}

export default app