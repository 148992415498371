import React, {Component} from 'react'

import Button from '@material-ui/core/Button'
import moment from 'moment'
import RedoIcon from '@material-ui/icons/Redo'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import {Chip, TextField} from '@material-ui/core'
import {grey,} from '@material-ui/core/colors'

class TableToolbar extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fromDate: moment().add(-4, 'weeks').format('YYYY-MM-DD'),
			untilDate: moment().format('YYYY-MM-DD'),
			filter: {},
		}
	}

	handleChangeFromDate = e => {
		this.props.handleChangeFromDate(e.target.value)
	}

	handleChangeUntilDate = e => {
		this.props.handleChangeUntilDate(e.target.value)
	}

	handleChangeFilter = f => {
		let temp = this.state.filter
		if (temp[`${f.f}`] === f.v) {
			delete temp[`${f.f}`]
		} else {
			temp[`${f.f}`] = f.v
		}
		this.setState({filter: temp})
		this.props.handleChangeFilter(temp)
	}

	render() {
		return (<div style={{alignSelf: 'center', marginTop: 0, marginBottom: 0, marginRight: 30, marginLeft: 30}}>
				<div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
					<div style={{display: 'flex', flexDirection: 'row'}}>
						<TextField
							id="fromDate"
							label="From"
							type="date"
							defaultValue={this.state.fromDate}
							onChange={this.handleChangeFromDate}
							InputLabelProps={{
								shrink: true,
							}}
						/>

						<TextField
							id="untilDate"
							type="date"
							label="Until"
							defaultValue={this.state.untilDate}
							onChange={this.handleChangeUntilDate}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</div>
					<div style={{display: 'flex', flexDirection: 'row'}}>
						{this.props.filterOpts.map((filterOpt, index) =>
							<Chip
								key={index}
								style={{
									marginLeft: 5,
									marginRight: 5,
									color: this.state.filter[`${filterOpt.f}`] === filterOpt.v ? '#fff' : null,
									backgroundColor: this.state.filter[`${filterOpt.f}`] === filterOpt.v ? grey[300] : null,
								}}
								label={filterOpt.v}
								onClick={this.handleChangeFilter.bind(this, filterOpt)}
							/>
						)}
					</div>
					<div>
						<Button
							disabled={!this.props.csvActive}
							variant="contained"
							color="default"
							onClick={() => this.props.handleDownloadCSV()}
						>Export CSV&nbsp;<DownloadIcon/></Button>
					</div>
					{this.props.openModalDialog && <div>
						<Button
							icon={<RedoIcon/>}
							disabled={!this.props.refundActive}
							onClick={this.props.openModalDialog}
						>Refund<RedoIcon/></Button>
					</div>}
				</div>
			</div>

		)
	}
}

export default TableToolbar