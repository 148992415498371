/**
 * Created by davidcarlson on 2017-01-03.
 */

import * as appsApi from './appsApi'
import history from '../history'

export const GET_APPS = 'GET_APPS'

export function getApps(token, country, appID) {
	return (dispatch) => {
		dispatch({
			type: 'IS_LOADING',
			isLoading: true
		})
		dispatch(saveApps([]))
		appsApi.getApps(token, country, 1).then(res => {
			const props = res.data.Properties
			if (props.Total > 0) {
				dispatch(saveApps(props.Elements))
				if (appID) {
					const e = props.Elements.filter(e => e.Properties.ID === appID)
					dispatch(saveApp(e[0]))
				}
			} else {
				history.push('/login')
				dispatch({
					type: 'AUTH_ERROR',
					error: 'not authorized'
				})
			}
			dispatch({
				type: 'IS_LOADING',
				isLoading: false
			})
		}).catch((err) => {
			history.push('/login')
			dispatch({
				type: 'AUTH_ERROR',
				error: err.toString()
			})

			dispatch({
				type: 'IS_LOADING',
				isLoading: false
			})
		})
	}
}

export const SAVE_APPS = 'SAVE_APPS'

export function saveApps(apps) {
	return {
		type: SAVE_APPS,
		apps
	}
}

export const SAVE_APP = 'SAVE_APP'

export function saveApp(selectedApp) {
	return {
		type: SAVE_APP,
		selectedApp
	}
}