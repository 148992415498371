/**
 * Created by davidcarlson on 2017-01-03.
 */
import {applyMiddleware, createStore} from 'redux'

import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly'
import {routerMiddleware} from 'react-router-redux'
import history from './history'

import reduxThunk from 'redux-thunk'

import rootReducer from './rootReducer'

const store = createStore(
	rootReducer,
	composeWithDevTools(
		applyMiddleware(reduxThunk, routerMiddleware(history))
	)
)

export default store