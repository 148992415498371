/**
 * Created by davidcarlson on 2017-01-12.
 */

export const ErrorMessages = {
	USERNAME_NOT_VALID: 'Username not valid',
	PASSWORD_ERROR: 'Please provide a password',
}

export const CLOSE_DRAWER = 'CLOSE_MODAL'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

export const ENABLE_BUTTON = 'ENABLE_BUTTON'
export const DISABLE_BUTTON = 'DISABLE_BUTTON'

export const SUBMIT_LOGIN = 'SUBMIT_LOGIN'
export const PROVIDER_SIGNIN = 'PROVIDER_SIGNIN'
export const NOTIFY_ERROR = 'NOTIFY_ERROR'

export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_USER = 'AUTH_USER'
export const SIGN_OUT_USER = 'SIGN_OUT_USER'
export const UNAUTH_USER = 'UNAUTH_USER'

export const PAYLOAD_ERROR = {
	'auth/user-not-found': 'Incorrect login details!',
	'auth/user-disabled': 'Account has been disabled'
}

export const FACEBOOK = 'facebook.com'
export const GOOGLE = 'google.com'

export const PROVIDER_URL = {
	'google.com': 'https://www.googleapis.com/auth/plus.login',
}