import React from 'react'
import {Redirect, Route} from 'react-router-dom'

const PrivateRoute = ({component: Component, ...rest}) => {
	if (rest.computedMatch.params && rest.computedMatch.params.app_id && rest.computedMatch.params.app_id.length > 0) {
		const e = rest.apps.filter(e => e.Properties.ID === rest.computedMatch.params.app_id)
		if (e && e.length > 0) {
			rest.saveApp(e[0])
		} else {
			rest.saveApp(undefined)
			return (<Redirect to="/apps"/>)
		}
	} else {
		rest.saveApp(undefined)
		return (<Redirect to="/apps"/>)
	}
	return (<Route
		{...rest}
		render={props => {
			return (<Component {...rest} {...props} />)
		}}
	/>)
}

export default PrivateRoute