import React from 'react'
import PropTypes from 'prop-types'
import TableComponent from '../table/TableComponent'

const sortOpts = [{
	name: 'Index',
	expr: 'ChapterIndex,LevelIndex'
}]

const ignoreAttrs = ['Deleted', 'Done', 'IntroSources', 'Index', 'Type', 'TypeFormatted', 'QuizIDs', 'MomentID', 'HtmlString']

const Levels = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'levels'}
		listSearchQuery={q => `ID:${q} OR ChapterIndex:${q} OR LevelIndex:${q}`}
		csvUrl={`/Country/${country_id}/App/${app_id}/Level/Csv`}
		downloadJsonUrl={`/Country/${country_id}/App/${app_id}/Levels?full=true&latest=true`}
		versionUrl={`/Country/${country_id}/App/${app_id}/Version/Level`}
		to={id => `/country/${country_id}/app/${app_id}/level/${id}`}
		{...{ignoreAttrs, sortOpts}}
		{...rest}/>

Levels.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Levels