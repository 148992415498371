/**
 * Created by davidcarlson on 2017-02-27.
 */
import axios from 'axios'

import {BASE_URL} from '../app/constants'

export function getApps(token, country, privilege) {
	if (!country) {
		return axios.get(`${BASE_URL}/Apps?privilege=${privilege}`, {
			headers: {
				'Authorization': 'Bearer ' + token,
				'Accept': 'application/json'
			}
		})
	}
	return axios.get(`${BASE_URL}/Country/${country}/Apps?privilege=${privilege}`, {
		headers: {
			'Authorization': 'Bearer ' + token,
			'Accept': 'application/json'
		}
	})
}
