import React from 'react'
import PropTypes from 'prop-types'

import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'Index'
	},
]

const ignoreAttrs = ['Deleted', 'Done']

const Videos = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'videos'}
		listSearchQuery={q => `ID:${q} OR Title:${q} OR Description:${q} OR Index:${q} OR YoutubeID:${q}`}
		csvUrl={`/Country/${country_id}/App/${app_id}/Video/Csv`}
		downloadJsonUrl={`/Country/${country_id}/App/${app_id}/Videos?full=true`}
		versionUrl={`/Country/${country_id}/App/${app_id}/Version/Video`}
		{...{ignoreAttrs, sortOpts}}
		{...rest}/>

Videos.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Videos