import React from 'react'
import * as PropTypes from 'prop-types'
import {
	DialogTitle,
	DialogActions,
	Button, DialogContent
} from '@material-ui/core'

class InfoDialog extends React.PureComponent {

	render () {
		const {close, title, text} = this.props
		return (
			<React.Fragment>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					{text}
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={() => close({refresh: true})} variant={'contained'} size='small'>
						Ok
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}
}

InfoDialog.propTypes = {
	title: PropTypes.string,
	text: PropTypes.string,
	close: PropTypes.func,
}

export default InfoDialog