import React from 'react'
import PropTypes from 'prop-types'
import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'SectionIndex,CategoryIndex'
	},
]

const ignoreAttrs = ['Deleted', 'Done']

const Theories = ({match: {params: {country_id, app_id}}, ...rest}) =>
		<TableComponent
			entityName={'theories'}
			listSearchQuery={q => `ID:${q} OR CategoryIndex:${q} OR Name:${q} OR Category:${q} OR SectionIndex:${q}`}
			csvUrl={`/Country/${country_id}/App/${app_id}/Theory/Csv`}
			downloadJsonUrl={`/Country/${country_id}/App/${app_id}/Theories?full=true&latest=true`}
			to={id => `/country/${country_id}/app/${app_id}/theory/${id}`}
			versionUrl={`/Country/${country_id}/App/${app_id}/Version/Theory`}
			{...{ignoreAttrs, sortOpts}}
			{...rest}/>

Theories.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Theories
