import React from 'react'
import * as PropTypes from 'prop-types'
import {InputBase, TableCell, TableRow} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import MiniSwitch from './MiniSwitch'
import Typography from '@material-ui/core/Typography'

const handleOnChange = (v, type, onChange, header) => {
	if (!v[0]) {
		onChange(header, '')
		return
	}
	if (type === 'integer') {
		v = v.reduce((a,e) => {
			if (!e) e = '0'
			try {
				const i = parseInt(e, 10)
				if (!isNaN(i)) {
					a.push(i)
				}
			} catch (e) {
				//do nothing
			}
			return a
		}, [])
	}
	onChange(header, v)
}

const getCellEditComponent = (editableAttr, header, onChange, value) => {
	if (!editableAttr) {
		switch (header) {
			case 'ID':
				return <Typography style={{fontSize: 10, fontWeight: 700, textAlign: 'center'}} color={'primary'}>New entity</Typography>
			case 'UpdatedAt':
				return <Button fullWidth size={'small'} color={'primary'} onClick={() => onChange('submit')}>Create</Button>
			default:
				return ''
		}
	}
	switch (editableAttr.type) {
		case 'integer':
			return <InputBase type={'number'} style={{fontSize: 10, color: '#3f51b5'}} value={!value && value !== 0 ? '' : value}
			                  onChange={e => onChange(header, parseInt(e.target.value < 0 ? 0 : e.target.value, 10))}
			                  placeholder={`${header}...`}/>
		case 'boolean':
			return <MiniSwitch checked={value || false} onChange={() => onChange(header, !value)}/>
		case 'array':
			return <InputBase type={'text'} style={{fontSize: 10, color: '#3f51b5'}} value={!value && value !== 0 ? '' :  `[${value}]`}
			                  onKeyDownCapture={e => {
				                  const {items: {type}} = editableAttr
				                  if (e.key === ',') {
					                  value.push(type === 'integer' ? 0 : '')
					                  handleOnChange(value, type, onChange, header)
				                  }
				                  if (e.key === 'Backspace') {
					                  if (value && value.length > 1 && value[value.length - 1] >= 0 && value[value.length - 1] < 10) {
						                  value.pop()
						                  value[value.length - 1] = type === 'integer' ? value[value.length - 1] * 10 : value[value.length - 1] + ' '
						                  handleOnChange(value, type, onChange, header)
					                  }
					                  return false
				                  }
			                  	return true
			                  }}
			                  onChange={e => {
				                  let {value} = e.target
				                  if (value.length > 0 && value[value.length -1] === ',') {
				                  	return
				                  }
				                  if (!value.includes(']') && value.includes('[')) {
					                  value = value.substring(0, value.length -1)
				                  }
				                  const v = value.replace('[','').replace(']','').replace(' ', '').split(',')
				                  const {items: {type}} = editableAttr
				                  handleOnChange(v, type, onChange, header)
			                  }}
			                  placeholder={`${header}...`}/>
		default:
			return <InputBase type={'text'} style={{fontSize: 10, color: '#3f51b5'}} value={!value && value !== 0 ? '' : value} onChange={e => onChange(header, e.target.value)}
			                  placeholder={`${header}...`}/>

	}
}

const CreateEntityTableRow = ({headers, editableAttrs, onChange, values}) =>
	<TableRow>
		{headers.map((header, j) =>
			<TableCell
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					minWidth: 5,
					maxWidth: 20,
					fontSize: 10,
					cursor: editableAttrs[header] ? 'pointer' : 'default',
					color: !editableAttrs[header] && '#3f51b5',
					padding: '10px 4px'
				}}
				colSpan={j === headers.length -1 ? 2 : 1}
				key={j}
			>
				{getCellEditComponent(editableAttrs[header], header, onChange, values[header])}
			</TableCell>
		)}
	</TableRow>

CreateEntityTableRow.propTypes = {
	onChange: PropTypes.func,
	headers: PropTypes.array,
	values: PropTypes.object,
	editableAttrs: PropTypes.object,
}

export default CreateEntityTableRow