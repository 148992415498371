import React from 'react'
import * as PropTypes from 'prop-types'
import NextIcon from '@material-ui/icons/ArrowForward'
import {Button, Paper, Table, TableHead, TableBody, TableCell, TableRow, Slide, IconButton} from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import {debounce} from 'throttle-debounce'
import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'
import {limitOpts} from '../constants'
import helpers from '../../components/helpers'
import ListToolbar from '../../components/list/ListToolbar'
import EntityTableRow from './EntityTableRow'
import Dialog from '@material-ui/core/Dialog'
import IntegerUpdateDialog from './dialogs/IntegerUpdateDialog'
import StringUpdateDialog from './dialogs/StringUpdateDialog'
import ArrayUpdateDialog from './dialogs/ArrayUpdateDialog'
import BooleanUpdateDialog from './dialogs/BooleanUpdateDialog'
import CreateEntityTableRow from './CreateEntityTableRow'
import DeleteEntityDialog from './dialogs/DeleteEntityDialog'
import fileDownload from 'js-file-download'
import InfoDialog from './dialogs/InfoDialog'
import ObjectUpdateDialog from './dialogs/ObjectUpdateDialog'
import {withRouter} from 'react-router-dom'
import SettingsDialog from './dialogs/SettingsDialog'

const dialogViews = {
	'settings': SettingsDialog,
	'integer': IntegerUpdateDialog,
	'string': StringUpdateDialog,
	'array': ArrayUpdateDialog,
	'object': ObjectUpdateDialog,
	'boolean': BooleanUpdateDialog,
	'delete': DeleteEntityDialog,
	'info': InfoDialog,
}

const tableHeaderStyle = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: 20,
	fontSize: 10,
	padding: '10px 4px',
}

const headersDefaultSort = (a, b) => {
	if (a === 'ID') return -1
	if (b === 'ID') return 1
	if (a === 'UpdatedAt') return 1
	if (b === 'UpdatedAt') return -1
	return a.localeCompare(b)
}

const getLanguage = countryCode => {
	if (!countryCode) return ''
	switch (countryCode) {
		case 'SWE':
			return 'se'
		case 'US':
			return 'en'
		default:
			return countryCode.toLowerCase()
	}
}

class TableComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			link: helpers.getURL(props.selectedApp, props.entityName),
			create: undefined,
			next: undefined,
			language: getLanguage(props.match.params.country_id),
			elements: undefined,
			headers: undefined,
			initHeaders: undefined,
			open: false,
			search: '',
			createPayload: {},
			sortOpt: 0,
			limitOpt: 4
		}
		this.callClient = debounce(1000, this.callClient)
	}

	componentDidMount() {
		this.callClient()
	}

	componentWillReceiveProps(newProps) {
		if (newProps.selectedApp !== this.props.selectedApp) {
			newProps.selectedApp && this.callClient()
		}
	}

	setLinks = data => {
		if (data && data.Links) {
			for (let link of data.Links) {
				this.setState({[link.Rel]: link})
			}
		}
	}

	filterElements = e => {
		let a = e.target.value
		if (a && a.length > 0) {
			if (a.indexOf(' ') !== -1) {
				a = `"${a}"`
			}
			let search = this.props.listSearchQuery(a)
			if (a.indexOf('ObjectID') > -1) {
				search = a
			}
			this.setState({search})
			this.callClient()
		} else if (this.state.search && this.state.search.length > 0) {
			this.setState({search: ''})
			this.callClient()
		}
	}

	uploadCsv = files => {
		if (files.length === 0) return
		const {uploadCsv, csvUrl, user: {user: {Token}}} = this.props
		const reader = new FileReader()
		reader.onload = () => {
			uploadCsv(Token, `https://${credentials.storageBucket}${csvUrl}`, files[0]).then(res => {
				if (res.status === 200) {
					this.setState({dialogView: 'info', open: true, title: 'CSV uploaded', text: 'Your data is in process. In several seconds your table with will be up to date. Please reload web view in order to see your imported data.'})
				} else {
					this.setState({dialogView: 'info', open: true, title: 'CSV is invalid', text: 'Something went wrong with the upload. Please check your csv file is correct.'})
				}
			})
		}
		reader.readAsText(files[0])
	}

	downloadCsv = () => {
		const {doRequest, csvUrl, entityName, user: {user: {Token}}} = this.props
		if (!csvUrl) return
		const link = {URL: `https://${credentials.storageBucket}${csvUrl}`, Method: 'GET'}
		doRequest(Token, link).then(res => {
			if (!res) return
			fileDownload(res.data, `${entityName}.csv`)
		})
	}

	downloadJson = () => {
		const {doRequest, downloadJsonUrl, entityName, user: {user: {Token}}} = this.props
		if (!downloadJsonUrl) return
		const link = {URL: `https://${credentials.storageBucket}${downloadJsonUrl}`, Method: 'GET'}
		doRequest(Token, link).then(res => {
			if (!res) return
			fileDownload(JSON.stringify(res.data, null, 2), `${entityName}.json`)
		})
	}

	callClient = token => {
		const {ignoreAttrs, headersSort = headersDefaultSort, sortOpts, selectedApp, entityName, doRequest} = this.props
		if (!token) {
			token = this.props.user.user.Token
		}
		let {link, search, limitOpt, sortOpt, language} = this.state
		if (!link) {
			link = helpers.getURL(selectedApp, entityName)
			search = ''
			this.setState({search, link})
		}
		doRequest(token, link, search, limitOpts[limitOpt], sortOpts && sortOpts[sortOpt], undefined, {lang: language}).then(res => {
			if (!res) return
			if (res.data) {
				this.setLinks(res.data)
				const elements = res.data.Properties.Elements
				let headers = []
				let editableAttrs = {}
				const createLink = res.data.Links ? res.data.Links.find(e => e.Rel === 'create') : undefined
				const createAttrs = createLink ? createLink.JSONSchema.properties : {}
				if (elements.length > 0) {
					headers = Object.keys(elements.reduce((map, e) => {
						Object.keys(e.Properties).filter(f => !ignoreAttrs.includes(f)).map(e => map[e] = '')
						return map
					}, {}))
					const updateLink = elements[0].Links.find(e => e.Rel === 'update')
					editableAttrs = updateLink ? updateLink.JSONSchema.properties : {}
				}
				if (createLink) {
					headers = Object.keys(createAttrs).filter(f => !ignoreAttrs.includes(f))
					headers = ['ID', 'UpdatedAt', ...headers]
				}
				headers.sort(headersSort)
				this.setState(prevState => ({elements, headers: prevState.headers || headers, initHeaders: headers, editableAttrs, createAttrs}))
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	handleSelectLimit = e => {
		this.setState({limitOpt: e})
		this.callClient()
	}

	handleSelectSort = e => {
		this.setState({sortOpt: e})
		this.callClient()
	}

	handleSelectNext() {
		if (this.state.next) {
			this.setState({link: this.state.next})
			this.callClient()
		}
	}

	updateEntity = (body, link) => {
		const token = this.props.user.user.Token
		this.props.doRequest(token, link, '', 0, '', body).then(() => {
			this.callClient(token)
			this.setState({open: false, createPayload: {}})
		})
	}

	showDialog = (value, link, props, text) => {
		const {type, title, items, properties} = props
		this.setState({dialogView: type, open: true, title, updateLink: link, value, items, properties, text})
	}

	showSettingsDialog = () => {
		const {initHeaders, headers} = this.state
		this.setState({dialogView: 'settings', open: true, title: 'Columns', properties: {headers, initHeaders}})
	}

	onChange = (key, value) => {
		if (key === 'submit') {
			const {createPayload, create} = this.state
			if (Object.keys(createPayload).length === 0) return
			this.updateEntity(createPayload, create)
			return
		}
		if (value === '') {
			let {createPayload} = this.state
			delete createPayload[key]
			this.setState({createPayload})
		} else {
			this.setState(state => ({createPayload: {...state.createPayload, ...{[key]: value}}}))
		}
	}

	onChangeLanguage = language => {
		this.setState({language})
		this.callClient()
	}

	render() {
		const {entityName, hideLanguage = false, maxWidth = 2000, csvUrl, downloadJsonUrl,versionUrl, to, headersSort = headersDefaultSort} = this.props
		const {elements, headers, editableAttrs, dialogView, language, open, title, value, updateLink, items, createPayload, create, createAttrs, text, properties} = this.state
		const DialogView = dialogViews[dialogView]
		return (
			<div style={{
				display: 'inline-block',
				height: '100%',
				width: '100%',
				minWidth: 560,
				maxWidth,
				margin: '0 10px 10px 10px'
			}}>
				<ListToolbar
					componentName={entityName}
					handleSelectSort={this.handleSelectSort}
					handleSelectLimit={this.handleSelectLimit}
					onChangeLanguage={!hideLanguage && language && this.onChangeLanguage}
					openCreateModalDialog={this.props.gotoElement}
					sortOpt={this.state.sortOpt}
					sortOpts={this.props.sortOpts}
					limitOpt={this.state.limitOpt}
					total={elements && elements.length}
					limitOpts={limitOpts}
					csvUrl={csvUrl}
					downloadJson={downloadJsonUrl ? this.downloadJson : undefined}
					versionUrl={versionUrl}
					downloadCsv={this.downloadCsv}
					uploadCsv={this.uploadCsv}
					filterElements={this.filterElements}
				/>
				{headers && editableAttrs && createAttrs &&
					<Paper>
						<Table size="small">
							<TableHead style={{backgroundColor: '#F5F5F5', borderTop: '1px solid #E4E4E4'}}>
								<TableRow style={{height: 50}}>
									{headers && headers.map((key, j) => <TableCell width={(key === 'ID' || key === 'UpdatedAt') ? '6%' : null} style={tableHeaderStyle} key={j}>{key}</TableCell>)}
									<TableCell align={'right'} style={{padding: '0 10px'}}><IconButton onClick={this.showSettingsDialog} size="small"><SettingsIcon/></IconButton></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{create && headers && createAttrs &&
								<CreateEntityTableRow key={0} onChange={this.onChange} values={createPayload} {...{
									headers,
									editableAttrs: createAttrs
								}}/>}
								{editableAttrs && elements && elements.map((element, i) =>
									<EntityTableRow key={i + 1}

									                showDialog={this.showDialog}
									                updateEntity={this.updateEntity}
									                {...{element, headers, editableAttrs, to}}/>
								)}
							</TableBody>
						</Table>
					</Paper>}
				{
					this.state.next ? <Button
							onClick={this.handleSelectNext.bind(this)}
							className='float-right'
							variant="contained" color="primary"
							style={{marginTop: 20, marginBottom: 30}}
						>{`Fetch more ${entityName}`}<NextIcon/></Button>
						: null
				}
				{DialogView &&
				<Dialog
					maxWidth={'sm'}
					fullWidth
					TransitionComponent={Slide}
					open={open} disableAutoFocus
					scroll={'body'}
				>
					<DialogView {...{title, value, items, text, properties, link: updateLink, headersSort}} update={this.updateEntity}
					            close={({refresh = false, ...rest}) => {
					            	this.setState({open: false, ...rest})
						            if (refresh) {
							            this.callClient()
						            }
					            }}/>
				</Dialog>
				}
			</div>
		)
	}
}

TableComponent.propTypes = {
	user: PropTypes.object,
	headersSort: PropTypes.func,
	onEntityIdClick: PropTypes.func,
	uploadCsv: PropTypes.func,
	entityName: PropTypes.string,
	maxWidth: PropTypes.number,
	csvUrl: PropTypes.string,
	versionUrl: PropTypes.string,
	downloadJsonUrl: PropTypes.string,
	doRequest: PropTypes.func,
	hideLanguage: PropTypes.bool,
	gotoElement: PropTypes.func,
	sortOpts: PropTypes.array,
}

export default withRouter(TableComponent)
