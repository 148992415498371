import React, {PureComponent} from 'react'
import * as PropTypes from 'prop-types'
import {TableCell, TableRow} from '@material-ui/core'
import moment from 'moment'
import {grey} from '@material-ui/core/colors'
import MiniSwitch from './MiniSwitch'
import DeleteIcon from '@material-ui/icons/Delete'
import {Link} from 'react-router-dom'

const sourceValid = value => {
	if (!value.URL) return false
	const splits = value.URL.split('/')
	const name = splits[splits.length - 1]
	return name !== value.Name
}

const getCellValue = (element, header, to) => {
	const value = element.Properties[header]
	if (header === 'ID' && to) {
		return <Link style={{color: '#3f51b5'}} to={to(element.Properties.ID)}>{element.Properties.ID}</Link>
	}
	if (header === 'UpdatedAt') return <span>{moment(value).format('YYYY-MM-DD HH:mm')}</span>
	switch (typeof value) {
		case 'number':
			return <span>{value}</span>
		case 'string':
			return <span title={value}>{value}</span>
		case 'object':
			let err = false
			if (Array.isArray(value)) {
				if (value.length > 0 && value[0].URL) {
					value.forEach(e => {
						if (!err) {
							err = sourceValid(e)
						}
					})
				}
			} else {
				err = sourceValid(value)
			}
			return <span style={{color: err && 'red'}}>{JSON.stringify(value)}</span>
		default:
			return <span>{JSON.stringify(value)}</span>
	}
}

class EntityTableRow extends PureComponent {
	render() {
		const {element, headers, editableAttrs, showDialog, updateEntity, to} = this.props
		return (
			<TableRow>
				{headers.map((header, i) =>
					<TableCell
						style={{
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
							minWidth: 5,
							maxWidth: 20,
							fontSize: 10,
							cursor: editableAttrs[header] ? 'pointer' : 'default',
							color: !editableAttrs[header] && grey[500],
							padding: '10px 4px'
						}}
						key={i}
						onClick={() => {
							if (header === 'ID') {
								return
							}
							if (!editableAttrs[header] || !element) return
							if (editableAttrs[header].type === 'boolean') return
							const updateLink = element.Links.filter(e => e.Rel === 'update')
							if (updateLink.length === 0) return
							showDialog(element.Properties[header], updateLink[0], editableAttrs[header])
						}}
					>
						{editableAttrs[header] && editableAttrs[header].type === 'boolean' ?
							<MiniSwitch checked={element.Properties[header]} onChange={() => {
								const updateLink = element.Links.filter(e => e.Rel === 'update')
								if (updateLink.length === 0) return
								updateEntity({[header]: !element.Properties[header]}, updateLink[0], editableAttrs[header])
							}}/> : getCellValue(element, header, to)}
					</TableCell>
				)}
				<TableCell
					title={'Delete'}
					key={headers.length + 1}
				style={{
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					width: 2,
					fontSize: 10,
				}}
				>
					<DeleteIcon onClick={() => {
						const deleteLink = element.Links.filter(e => e.Rel === 'delete')
						if (deleteLink.length === 0) return
						showDialog(undefined, deleteLink[0], {type: 'delete', title: `Delete ${element.Type.toLowerCase()} ${element.Properties.ID}?`})
					}} className={'img-clear-btn'} style={{width: 14, height: 14, cursor: 'pointer'}}/>
				</TableCell>
			</TableRow>
		)
	}
}

EntityTableRow.propTypes = {
	onEntityIdClick: PropTypes.func,
	updateEntity: PropTypes.func,
	showDialog: PropTypes.func,
	onChange: PropTypes.func,
	element: PropTypes.object,
	headers: PropTypes.array,
	editableAttrs: PropTypes.object,
}

export default EntityTableRow