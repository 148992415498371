module.exports = {
	apiKey:
		process.env.REACT_APP_STAGE === 'development'
			? 'AIzaSyACb-Hr8X0rvCINyq4HgAyDTooBn8kHTM8'
			: 'AIzaSyDXXykkNJiWxWHGWgH1oCyGYwBv578KHw0',
	authDomain:
		process.env.REACT_APP_STAGE === 'development'
			? 'luminous-inferno-3528.firebaseapp.com'
			: 'teoriappar-prod.firebaseapp.com',
	databaseURL:
		process.env.REACT_APP_STAGE === 'development'
			? 'https://luminous-inferno-3528.firebaseio.com'
			: 'https://teoriappar-prod.firebaseio.com',
	projectId:
		process.env.REACT_APP_STAGE === 'development'
			? 'luminous-inferno-3528'
			: 'teoriappar-prod',
	storageBucket:
		process.env.REACT_APP_STAGE === 'development'
			? 'luminous-inferno-3528.appspot.com'
			: 'teoriappar-prod.appspot.com',
	messagingSenderId:
		process.env.REACT_APP_STAGE === 'development' ? '191623131265' : '997597258092'
}
