/**
 * Created by davidcarlson on 2017-01-03.
 */

import {SAVE_DETAILS, SAVE_ROLE, SAVE_USER} from './actions'

const initialState = {
	user: undefined,
	details: undefined,
	role: undefined
}

function organization(state = initialState, action) {
	switch (action.type) {
		case SAVE_USER:
			return {
				...state,
				user: {...action.user}
			}
		case SAVE_ROLE:
			return {
				...state,
				role: action.role
			}
		case SAVE_DETAILS:
			return {
				...state,
				details: action.userDetails
			}
		default:
			return state
	}
}

export default organization