import React, {Component} from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Header from '../header/Header'
import Spinner from '../components/spinner/Spinner'
import * as appActions from '../app/actions'
import * as appsActions from '../apps/actions'
import * as loginActions from '../login/actions'
import * as userActions from '../profile/actions'
import * as clientActions from '../client/actions'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import '../../assets/stylesheet/helper.css'
import credentials from '../firebase/credentials'
import firebase from 'firebase/app'
import 'firebase/auth'
import history from '../history'
import PrivateRoute from '../PrivateRoute'
import Apps from '../apps/Apps'
import Sources from '../components/sources/Sources'
import Questions from '../components/question/Questions'
import Levels from '../components/level/Levels'
import Theories from '../components/theory/Theories'
import Theory from '../components/theory/Theory'
import Level from '../components/level/Level'
import Payments from '../components/payments/Payments'
import Question from '../components/question/Question'
import RoadSigns from '../components/roadsign/RoadSigns'
import RoadSign from '../components/roadsign/RoadSign'
import Installations from '../components/installations/Installations'
import NotFound from '../notFound/NotFound'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import Checklists from '../components/checklist/Checklists'
import Qas from '../components/qa/Qas'
import Videos from '../components/video/Videos'
import Users from '../components/users/Users'
import DogBreeds from '../components/dogbreed/DogBreeds'
import Wordlists from '../components/wordlist/Wordlists'

class App extends Component {
	componentWillMount() {
		try {
			firebase.initializeApp(credentials)
		} catch (e) {
			console.warn('firebase app already created')
		}
		firebase.auth().onAuthStateChanged(user => {
			if (user !== null) {
				this.props.authUser(user)
				user.getIdToken(true).then(token => {
					this.props.getApps(token)
					this.props.saveUser({
						ID: user.uid,
						Email: user.email,
						Name: user.displayName,
						Token: token
					})
				})
			} else {
				history.push('/login')
			}
		})
	}

	getPathName(path) {
		const array = path.split('/')
		return array[array.length - 1]
	}

	hasUserAndApps() {
		return (this.props.apps && this.props.apps.length > 0 && this.props.user && this.props.user.user)
	}

	render() {
		return (
			<div className="full-height">
				{this.renderHeader()}
				{this.renderRedirect()}
				{this.hasUserAndApps() ? <div style={styles.container}>
						<Switch>
							<PrivateRoute path="/country/:country_id/app/:app_id/questions" {...this.props}
							              component={Questions}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/levels" {...this.props}
							              component={Levels}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/theories" {...this.props}
							              component={Theories}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/roadsigns" {...this.props}
							              component={RoadSigns}/>
              <PrivateRoute path="/country/:country_id/app/:app_id/checklists" {...this.props}
							              component={Checklists}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/wordlists" {...this.props}
														component={Wordlists}/>
              <PrivateRoute path="/country/:country_id/app/:app_id/qas" {...this.props}
							              component={Qas}/>
              <PrivateRoute path="/country/:country_id/app/:app_id/videos" {...this.props}
							              component={Videos}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/dogbreeds" {...this.props}
							              component={DogBreeds}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/sources" {...this.props}
							              component={Sources}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/payments" {...this.props}
							              component={Payments}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/installations" {...this.props}
							              component={Installations}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/theory/:theory_id" {...this.props}
							              component={Theory}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/level/:level_id" {...this.props}
							              component={Level}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/question/:question_id" {...this.props}
							              component={Question}/>
							<PrivateRoute path="/country/:country_id/app/:app_id/roadsign/:roadsign_id" {...this.props}
							              component={RoadSign}/>

							<Route path="/users" render={props => <Users {...this.props} {...props} />}/>
							<Route path="/apps" render={props => <Apps {...this.props} {...props} />}/>
							<Route path="*" component={NotFound}/>
						</Switch>
					</div>
					: null
				}

				<Spinner open={this.props.app.isLoading}/>
				<Snackbar
					open={this.props.app.snackBarOpen && !!this.props.app.error}
					message={!!this.props.app.error ? this.props.app.error : false}
					action="close"
					autoHideDuration={this.props.app.autoHideDuration}
					onActionTouchTap={this.props.closeSnackBar}
					onRequestClose={this.props.closeSnackBar}
				/>
			</div>
		)
	}

	renderHeader() {
		if (this.props.apps) {
			return <Header pathName={this.getPathName(this.props.location.pathname)}/>
		}
	}

	renderRedirect() {
		if (this.props.location.pathname === '/') {
			return <Redirect to="/apps"/>
		}
	}
}

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		paddingTop: 10,
		width: '100%'
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({...loginActions, ...appActions, ...userActions, ...appsActions, ...clientActions}, dispatch)
}

function mapStateToProps(state, routeProps) {
	return {
		app: state.app,
		login: state.login,
		routeProps,
		user: state.user,
		apps: state.apps.apps,
		selectedApp: state.apps.selectedApp,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(props => <App {...props} />))