import React from 'react'
import * as PropTypes from 'prop-types'
import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'Index'
	},
]

const ignoreAttrs = ['Deleted']

const DogBreeds = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'dog-breeds'}
		listSearchQuery={q => `ID:${q} OR Name:${q} OR Index:${q}`}
		downloadJsonUrl={`/DogBreeds?full=true&latest=true`}
		csvUrl={`/Country/${country_id}/App/${app_id}/DogBreed/Csv`}
		{...{ignoreAttrs, sortOpts}}
		{...rest}/>

DogBreeds.propTypes = {
	match: PropTypes.object.isRequired,
}

export default DogBreeds