/**
 * Created by davidcarlson on 2017-02-17.
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {CircularProgress} from '@material-ui/core'

class Spinner extends Component {
	render() {
		return (
			this.props.open ? <div style={{
				position: 'fixed',
				height: '100%',
				width: '100%',
				top: 0,
				left: 0,
				alignItems: 'center',
				opacity: 1,
				backgroundColor: 'rgba(0, 0, 0, 0.541176)',
				zIndex: '1600',
				display: 'flex',
				alignContent: 'center',
				justifyContent: 'center',
			}}>
				<CircularProgress size={70} style={{color: '#fff'}} thickness={7}/>
			</div> : null
		)
	}
}

Spinner.propTypes = {
	open: PropTypes.bool,
}

export default Spinner