import React, {useState} from 'react'
import {MenuItem, TextField} from '@material-ui/core'
import {withRouter} from 'react-router-dom'

const getLanguage = countryCode => {
  if (!countryCode) return 'se'
  switch (countryCode) {
    case 'SWE':
      return 'se'
    case 'US':
      return 'en'
    default:
      return countryCode.toLowerCase()
  }
}

const LanguageSelector = ({onChange, match: {params: {country_id}}}) => {
  const [value, setValue] = useState(getLanguage(country_id))
  return (
    <TextField
      label="Language"
      select
      size={'small'}
      margin="dense"
      style={{marginRight: 8}}
      variant="outlined"
      value={value}
      onChange={e => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
    >
      <MenuItem value={'se'}>
        Swedish
      </MenuItem>
      <MenuItem value={'en'}>
        English
      </MenuItem>
      <MenuItem value={'no'}>
        Norwegian
      </MenuItem>
      <MenuItem value={'es'}>
        Spanish
      </MenuItem>
      <MenuItem value={'pl'}>
        Polish
      </MenuItem>
    </TextField>
  )

}

export default withRouter(LanguageSelector)