/**
 * Created by davidcarlson on 2017-01-03.
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import helpers from '../helpers'
import {debounce} from 'throttle-debounce'
import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'
import {Bar} from 'react-chartjs-2'
import fileDownload from 'js-file-download'
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core'

import TableToolbar from '../list/TableToolbar'

const COMPONENT_NAME = 'payments'

class Payments extends Component {
	constructor(props) {
		super(props)
		this.state = {
			rescModalOpen: false,
			modalHeader: undefined,
			modalBody: undefined,
			link: helpers.getURL(props.selectedApp, COMPONENT_NAME),
			create: undefined,
			next: undefined,
			elements: undefined,
			search: '',
			refundActive: false,
			csv: false,
			sortOpt: 0,
			limitOpt: 0,
			totalAmount: 0,
			netAmount: 0,
			feeAmount: 0,
			vatAmount: 0,
			selectedRow: -1,
			from: moment().add(-4, 'weeks').toISOString(),
			until: moment().add(1, 'day').toISOString(),
			statistics: {
				labels: [],
				datasets: [],
			},
			filters: {},
		}
		this.callClient = debounce(1000, this.callClient)
	}

	componentDidMount() {
		this.callClient()
	}

	handleChangeFilter = f => {
		this.setState({filters: f})
		this.callClient()
	}

	handleSelectFrom = date => {
		this.setState({from: moment(date).toISOString()})
		this.callClient()
	}

	handleSelectUntil = date => {
		this.setState({until: moment(date).add(1, 'day').toISOString()})
		this.callClient()
	}

	handleDownloadCSV = () => {
		this.setState({csv: true})
		this.callClient()
	}

	setLinks = data => {
		if (data) {
			for (let link of data.Links) {
				this.setState({[link.Rel]: link})
			}
		}
	}

	callClient = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		if (!this.state.link) {
			this.setState({search: '', link: helpers.getURL(this.props.selectedApp, COMPONENT_NAME)})
			this.callClient(token)
		}
		let f = this.state.filters
		f.from = this.state.from
		f.until = this.state.until
		f.csv = this.state.csv
		if (!this.state.link) {
			return
		}
		this.props.doRequest(token, this.state.link, undefined, undefined, undefined, undefined, f).then(res => {
			if (res && res.data) {
				if (this.state.csv) {
					fileDownload(res.data, `payments_${moment(this.state.from).format('YYYY-MM-DD')}_${moment(this.state.until).format('YYYY-MM-DD')}.csv`)
					this.setState({csv: false})
				} else {
					this.setLinks(res.data)
					this.setState({elements: res.data.Properties.Elements})
				}
				this.setStatistics('YYYY-MM-DD')
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	selectPayment = i => {
		this.setState({
			refundActive: i.length > 0 && this.state.elements[i[0]].Properties.Status === 'paid',
			selectedRow: i.length > 0 ? i[0] : -1
		})
	}

	getColor = status => {
		switch (status) {
			case 'failed':
				return '#fb5946'
			case 'declined':
				return '#5498e9'
			case 'refunded':
				return '#ecdb4f'
			case 'refunding':
				return '#ecdb4f'
			default:
				return '#7AD171'
		}
	}

	listContainer(elements) {
		return <Table ref="table"
		              onChange={this.selectPayment}
		>
			<TableHead>
				<TableRow>
					<TableCell>Order</TableCell>
					<TableCell>Type</TableCell>
					<TableCell>Status</TableCell>
					<TableCell>Amount</TableCell>
					<TableCell>VAT</TableCell>
					<TableCell>Fee</TableCell>
					<TableCell>Net</TableCell>
					<TableCell>Created</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{
					elements.map((row, index) =>
						<TableRow style={{cursor: 'pointer'}} key={index} selected={this.state.selectedRow === index}
						          selectable={`${row.Properties.Status === 'paid'}`}>
							<TableCell children={row.Properties.OrderID}/>
							<TableCell children={row.Properties.Type}/>
							<TableCell children={row.Properties.Status}/>
							<TableCell
								children={helpers.renderAmount(row.Properties.TotalAmount, row.Properties.Currency)}/>
							<TableCell
								children={helpers.renderAmount(row.Properties.VATAmount, row.Properties.Currency)}/>
							<TableCell
								children={helpers.renderAmount(row.Properties.FeeAmount, row.Properties.Currency)}/>
							<TableCell
								children={helpers.renderAmount(row.Properties.NetAmount, row.Properties.Currency)}/>
							<TableCell children={`${moment(row.Properties.CreatedAt).format('dd D MMM YYYY HH:mm')}`}/>
						</TableRow>
					)}
			</TableBody>
		</Table>
	}

	setStatistics = dateFormat => {
		let statuses = {}
		let obj = {}
		let totalAmount = 0
		let netAmount = 0
		let feeAmount = 0
		let vatAmount = 0
		for (let i = 0; i < this.state.elements.length; i++) {

			const groupTime = moment(this.state.elements[i].Properties.CreatedAt).format(dateFormat)
			if (obj[groupTime + '' + this.state.elements[i].Properties.Status]) {
				obj[groupTime + '' + this.state.elements[i].Properties.Status] = obj[groupTime + '' + this.state.elements[i].Properties.Status] + (this.state.elements[i].Properties.TotalAmount / 100)
			} else {
				obj[groupTime + '' + this.state.elements[i].Properties.Status] = (this.state.elements[i].Properties.TotalAmount / 100)
			}
			if (this.state.elements[i].Properties.Status === 'paid') {
				totalAmount += this.state.elements[i].Properties.TotalAmount
				netAmount += this.state.elements[i].Properties.NetAmount
				feeAmount += this.state.elements[i].Properties.FeeAmount
				vatAmount += this.state.elements[i].Properties.VATAmount
			}
			if (statuses[this.state.elements[i].Properties.Status]) {
				statuses[this.state.elements[i].Properties.Status].push(obj)
			} else {
				statuses[this.state.elements[i].Properties.Status] = []
				statuses[this.state.elements[i].Properties.Status].push(obj)
			}
		}
		const timeSlots = this.getTimeSlots(this.state.from, this.state.until, dateFormat)
		let datasets = []
		let keys = Object.keys(statuses)
		for (let i = 0; i < keys.length; i++) {
			let resourceList = statuses[keys[i]]
			let usageData = {}
			for (let j = 0; j < timeSlots.length; j++) {
				let temp = true
				for (let k = 0; k < resourceList.length; k++) {
					if (resourceList[k][timeSlots[j] + '' + keys[i]]) {
						usageData[timeSlots[j]] = resourceList[k][timeSlots[j] + '' + keys[i]]
						temp = false
					}
				}
				if (temp) {
					usageData[timeSlots[j]] = 0
				}
			}
			const color = this.getColor(keys[i])
			datasets.push({
				label: keys[i],
				fill: false,
				lineTension: 1,
				backgroundColor: color,
				borderColor: color,
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0,
				borderJoinStyle: 'miter',
				pointBorderColor: color,
				pointBackgroundColor: '#fff',
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: color,
				pointHoverBorderColor: 'rgba(220,220,220,1)',
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: Object.keys(usageData).map(e => {
					return usageData[e]
				}),
				spanGaps: false
			})
		}
		this.setState({
			statistics: {labels: timeSlots, datasets: datasets},
			totalAmount: totalAmount,
			netAmount: netAmount,
			feeAmount: feeAmount,
			vatAmount: vatAmount,
		})
	}

	getTimeSlots = (start, end, dateFormat) => {
		let timeSlots = []
		let format = 'days'
		switch (dateFormat) {
			case 'YYYY-MM-DD HH:mm':
				format = 'minute'
				break
			case 'YYYY-MM-DD':
				format = 'days'
				break
			case 'YYYY-MM-DD HH':
				format = 'hours'
				break
			default:
				format = 'hours'
				break
		}
		let currentTime = moment(start).format(dateFormat)
		while (moment(currentTime).isBefore(moment(end).format(dateFormat))) {
			timeSlots.push(currentTime)
			currentTime = moment(currentTime).add(1, format).format(dateFormat)
		}
		return timeSlots
	}

	render() {
		return (
			<div style={{
				height: '100%',
				width: '100%',
			}}>
				<div>
					<div style={{padding: 10}}>
						<TableToolbar
							refundActive={this.state.refundActive}
							csvActive={this.state.elements && this.state.elements.length > 0}
							handleChangeFromDate={this.handleSelectFrom}
							handleChangeUntilDate={this.handleSelectUntil}
							handleDownloadCSV={this.handleDownloadCSV}
							handleChangeFilter={this.handleChangeFilter}
							filterOpts={[{f: 'status', v: 'paid'}, {f: 'status', v: 'failed'}, {
								f: 'status',
								v: 'declined'
							}, {f: 'status', v: 'refunded'}, {f: 'type', v: 'stripe'}, {f: 'type', v: 'swish'}]}
							//TODO handle openModalDialog
							openModalDialog={null}
						/>
						{!!this.state.elements ? !!this.state.elements.length > 0 ? <div>
								<div style={{maxHeight: 350}}>
									<Bar
										data={this.state.statistics}
										width={100}
										height={350}
										options={{
											maintainAspectRatio: false,
											animation: false,
										}}
									/>
								</div>
								<hr/>
								<div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
									<div style={{display: 'flex', flexDirection: 'column', marginRight: 30}}>
	                    <span><label>Total Amount:</label>&nbsp;<b
		                    style={{fontSize: '1.3em'}}>{`${helpers.renderAmount(this.state.totalAmount, 'SEK')}`}</b></span>
										<span><label>Net Amount:</label>&nbsp;<b
											style={{fontSize: '1.3em'}}>{`${helpers.renderAmount(this.state.netAmount, 'SEK')}`}</b></span>
									</div>
									<div style={{display: 'flex', flexDirection: 'column'}}>
	                    <span><label>Fee Amount:</label>&nbsp;<b
		                    style={{fontSize: '1.3em'}}>{`${helpers.renderAmount(this.state.feeAmount, 'SEK')}`}</b></span>
										<span><label>VAT Amount:</label>&nbsp;<b
											style={{fontSize: '1.3em'}}>{`${helpers.renderAmount(this.state.vatAmount, 'SEK')}`}</b></span>
									</div>
								</div>
								<hr/>
							</div>
							: null
							: null
						}
					</div>

					{!!this.state.elements ? !!this.state.elements.length > 0 ? this.listContainer(this.state.elements)
						: <div
							style={{
								textTransform: 'uppercase',
								height: 100,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '40px 0px'
							}}
						>
							<h4>{`No ${COMPONENT_NAME} found`}</h4>
						</div>

						: null
					}
				</div>
			</div>
		)
	}
}

Payments.propTypes = {
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	selectedApp: PropTypes.object.isRequired,
	routeProps: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	saveUser: PropTypes.func,
}

export default Payments
