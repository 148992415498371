import React from 'react'
import * as PropTypes from 'prop-types'
import {
	DialogTitle,
	DialogActions,
	Button
} from '@material-ui/core'

class DeleteEntityDialog extends React.PureComponent {

	render () {
		const {close, update, title, link} = this.props
		return (
			<React.Fragment>
				<DialogTitle>{title}</DialogTitle>
				<DialogActions>
					<Button color="default" onClick={close} variant={'contained'} size='small'>
						Cancel
					</Button>
					<Button color="secondary" onClick={() => update(undefined, link)} variant={'contained'} size='small'>
						Delete
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}
}

DeleteEntityDialog.propTypes = {
	link: PropTypes.object,
	title: PropTypes.string,
	close: PropTypes.func,
	update: PropTypes.func,
}

export default DeleteEntityDialog