import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import * as headerActions from './actions'
import * as loginActions from '../login/actions'
import MainDrawer from './mainDrawer/MainDrawer'
import NotificationDrawer from './notificationDrawer/NotificationDrawer'
import {AppBar, Button, IconButton, Toolbar, Typography} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

class Header extends Component {
	constructor(props) {
		super(props)

		this.state = {
			mainDrawerOpen: false,
			notificationDraweOpen: false,
			profileImgUrl: props.user.details ? props.user.details.ImageURL : null,
			country: props.country,
			anchorEl: null,
			open: false
		}
	}

	getHeaderTitle() {
		return this.props.selectedApp ? this.props.selectedApp.Name : this.props.pathName
	}

	openMainDrawer(bool) {
		this.setState({mainDrawerOpen: bool})
	}

	openNotificationDrawer(bool) {
		this.setState({notificationDraweOpen: bool})
	}

	render() {
		return (
			<div>
				<AppBar position="static">
					<Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
						<IconButton color="inherit" aria-label="Menu" onClick={this.openMainDrawer.bind(this, true)}>
							<MenuIcon/>
						</IconButton>
						<Typography
							style={{textTransform: 'uppercase', color: '#fff'}}>{this.getHeaderTitle()}</Typography>
						<Button color="inherit" onClick={this.props.signOutUser}>Logout</Button>
					</Toolbar>
				</AppBar>


				<MainDrawer {...this.props} isOpen={this.state.mainDrawerOpen}
				            closeMainDrawer={this.openMainDrawer.bind(this, false)} currPath={this.props.pathName}/>
				<NotificationDrawer isOpen={this.state.notificationDrawerOpen}
				                    closeDrawer={this.openNotificationDrawer.bind(this, false)}
				                    notifications={this.props.app.notifications}/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		app: state.app,
		header: state.header,
		login: state.login,
		organization: state.organization,
		user: state.user,
		country: state.country ? state.country.country : null,
		apps: state.apps ? state.apps.apps : null,
		selectedApp: state.apps ? state.apps.selectedApp : null,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({...headerActions, ...loginActions}, dispatch)
}

Header.propTypes = {
	app: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	selectedApp: PropTypes.object,
	pathName: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)