import {DO_REQUEST, UPLOAD_CSV} from './actions'

const initialState = {
	error: null,
	isLoading: false,
}

function client(state = initialState, action) {

	switch (action.type) {
		case DO_REQUEST:
			return Object.assign({}, state, {
				isLoading: true,
			})
		case UPLOAD_CSV:
			return Object.assign({}, state, {
				isLoading: true,
			})
		default:
			return state
	}
}

export default client