import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {AppBar, Drawer, IconButton} from '@material-ui/core'
import ClearAll from '@material-ui/icons/ClearAll'

import history from '../../history'

class NotificationDrawer extends Component {
	handleLocationChange = (param) => {
		history.push(param)
	}

	render() {
		return (
			<Drawer
				docked={false}
				open={this.props.isOpen}
				onRequestChange={this.props.closeDrawer}
				openSecondary={false}
			>
				<AppBar
					position="static"
					title="Notifications"
					showMenuIconButton={false}
					iconElementRight={
						<IconButton>
							<ClearAll/>
						</IconButton>
					}
				/>

			</Drawer>
		)
	}
}

NotificationDrawer.propTypes = {
	isOpen: PropTypes.bool,
	closeDrawer: PropTypes.func
}

export default NotificationDrawer