import React from 'react'
import * as PropTypes from 'prop-types'
import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'Index'
	},
]

const ignoreAttrs = ['Deleted']

const Wordlists = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'word-lists'}
		listSearchQuery={q => `ID:${q} OR Word:${q} OR Description:${q} OR Index:${q}`}
		downloadJsonUrl={`/Country/${country_id}/App/${app_id}/WordLists?full=true&latest=true`}
		csvUrl={`/Country/${country_id}/App/${app_id}/WordList/Csv`}
		versionUrl={`/Country/${country_id}/App/${app_id}/Version/WordList`}
		{...{ignoreAttrs, sortOpts}}
		{...rest}/>

Wordlists.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Wordlists