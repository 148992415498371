import React from 'react'
import * as PropTypes from 'prop-types'
import {
	DialogContent,
	DialogTitle,
	DialogActions,
	Button
} from '@material-ui/core'
import ObjectInput from './ObjectInput'

class ObjectUpdateDialog extends React.Component {
	constructor (props) {
		super(props)
		const {value = {}} = props
		this.state = {
			value
		}
	}

	render () {
		const {close, update, title, link, properties} = this.props
		const {value} = this.state
		return (
			<React.Fragment>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<ObjectInput
						properties={properties}
						value={this.state.value}
						margin="normal"
						onChange={value => {
							this.setState({value})
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={close} variant={'contained'} size='small'>
						Cancel
					</Button>
					<Button color="primary" onClick={() => update({[title]: value}, link)} variant={'contained'} size='small'>
						Update
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}
}

ObjectUpdateDialog.propTypes = {
	link: PropTypes.object,
	properties: PropTypes.object,
	value: PropTypes.object,
	title: PropTypes.string,
	close: PropTypes.func,
	update: PropTypes.func,
}

export default ObjectUpdateDialog