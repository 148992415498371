import React, {Component} from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'
import helpers from '../helpers'
import TinyMCE from 'react-tinymce'
import {Grow, Paper, TextField} from '@material-ui/core'
import {CREATE, UPDATE} from '../../client/Client'
import ActionToolbar from '../ActionToolbar'

class Theory extends Component {
	constructor(props) {
		super(props)
		this.handleEditorChange = this.handleEditorChange.bind(this)
		const {country_id, app_id, theory_id} = props.match.params
		this.state = {
			countryID: country_id,
			appID: app_id,
			ID: theory_id,
			imageBaseURL: `https://storage.googleapis.com/${credentials.storageBucket}/${country_id}/${app_id}/image/`,
			element: theory_id === CREATE ? {
				Properties: {}
			} : undefined,
			imageList: undefined
		}
		this.submitForm = this.submitForm.bind(this)
	}

	handleEditorChange(e) {
		let temp = this.state.element
		temp.Properties.HtmlString = e.target.getContent()
		this.setState({element: temp})
	}

	submitForm(e) {
		let temp = this.state.element.Properties
		temp.HtmlString = this.convertHtmlString(this.state.element.Properties.HtmlString)
		this.handleRequest(this.state.ID === CREATE ? this.state.ID : UPDATE, temp)
		e.preventDefault()
	}

	componentDidMount() {
		this.callClient()
		this.getImageList()
	}

	setLinks = data => {
		if (data) {
			for (let link of data.Links) {
				this.setState({[link.Rel]: link})
			}
		}
	}

	callClient = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		if (this.state.ID === CREATE) {
			return
		}
		const link = {
			Method: 'GET',
			URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Theory/${this.state.ID}`
		}
		this.props.doRequest(token, link).then(res => {
			if (res && res.data) {
				this.setLinks(res.data)
				this.setState({element: res.data})
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	getImageList = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		const link = {
			Method: 'GET',
			URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Sources`
		}
		this.props.doRequest(token, link).then(res => {
			if (res && res.data && res.data.Properties) {
				const {Elements} = res.data.Properties
				this.setState({
					imageList: Elements.map(e => {
						return {value: e.Name, title: e.Name}
					})
				})
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.getImageList(token)
					})
				})
			} else {
				this.setState({imageList: []})
			}
		})
	}

	convertHtmlString(h) {
		let htmlString = h
		const {CountryID, ID} = this.props.selectedApp.Properties
		let re = new RegExp(`https://storage.googleapis.com/${credentials.storageBucket}/${CountryID}/${ID}/image/`, 'g')
		return htmlString.replace(re, '')
	}

	handleRequest = (action, data, token) => {
		if (!token) {
			token = this.props.user.user.Token
		}
		let url
		if (action === CREATE) {
			url = {
				URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Theories`,
				Method: 'POST'
			}
		} else {
			url = helpers.getURL(this.state.element, action)
		}
		if (url) {
			this.props.doRequest(token, url, '', 0, '', data).then(res => {
				if (res && res.data) {
					this.setState({element: res.data})
				} else if (res.refreshToken) {
					try {
						firebase.initializeApp(credentials)
					} catch (e) {
						console.warn('firebase app already created')
					}
					firebase.auth().onAuthStateChanged(user => {
						user.getIdToken(true).then(token => {
							this.props.saveUser({
								ID: user.uid,
								Email: user.email,
								Name: user.displayName,
								Token: token
							})
							this.handleRequest(action, data, token)
						})
					})
				}
			})
		}
	}

	textField = v => <TextField
		name={v}
		label={v}
		style={{margin: 5}}
		value={this.state.element.Properties[v]}
		fullWidth={true}
		required
		variant="outlined"
		margin="normal"
		onChange={e => {
			let temp = this.state.element
			temp.Properties[v] = e.target.value
			this.setState({element: temp})
		}}
	/>

	numberField = v => <TextField
		style={{margin: 5}}
		name={v}
		label={v}
		value={this.state.element.Properties[v]}
		fullWidth={true}
		required
		margin="normal"
		type="number"
		variant="outlined"
		onChange={e => {
			let temp = this.state.element
			temp.Properties[v] = parseInt(e.target.value, 10)
			this.setState({element: temp})
		}}
	/>

	render() {
		return (
			<div className="entity-container">
				{this.state.element && <div>
					<form onSubmit={this.submitForm} autoComplete="off">
						<ActionToolbar
							id={this.state.ID}
							handleRequest={this.handleRequest}
						/>
						<Grow in={!!this.state.element}>
							<Paper style={{marginBottom: 10}}>
								<div>
									<div style={{padding: 19}}>
										<div className="flex-row full-width">
											{this.textField('Name')}
											{this.textField('Category')}
										</div>
										<div className="flex-row full-width">
											{this.numberField('CategoryIndex')}
											{this.numberField('SectionIndex')}
											{this.numberField('Index')}
											{this.numberField('EstimatedReadTimeMinutes')}
										</div>
									</div>
								</div>
							</Paper>
						</Grow>
						<Grow in={!!this.state.imageList}>
							<Paper>
								<div style={{display: 'flex', flexDirection: 'column', flexFlow: 'column'}}>
									{this.state.imageList && <TinyMCE
										content={this.state.element.Properties.HtmlString}
										config={{
											content_css: '/css/app_style.css',
											force_br_newlines: false,
											force_p_newlines: false,
											forced_root_block: '',
											visual: false,
											entity_encoding: 'raw',
											height: '100vh',
											image_prepend_url: this.state.imageBaseURL,
											image_dimensions: false,
											image_description: false,
											image_list: this.state.imageList,
											browser_spellcheck: true,
											plugins: 'image link code lists',
											image_class_list: [
												{title: 'Default', value: 'imageCenter'}
											],
											menubar: false,
											toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | code | image | quote ',
											setup: ed => {
												ed.addCommand('theoryAppsQuote', () => {
													let se = ed.selection.getContent()
													if (se.trim() === '') {
														alert('Nothing Selected.')
														return
													}
													let s1 = '<div class="quote" >'
													s1 += se + '</div>'

													ed.selection.setContent(s1)
												})
												ed.addButton('quote', {
													title: 'Quote',
													image: `https://${credentials.storageBucket}/img/quote.png`,
													onclick: () => {
														ed.insertContent('<div class="quote" >quote</div>')
													}
												})
											}
										}}
										onChange={this.handleEditorChange}
									/>}
								</div>
							</Paper>
						</Grow>
					</form>
				</div>}
			</div>
		)
	}
}

Theory.propTypes = {
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	selectedApp: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	saveUser: PropTypes.func,
}

export default Theory
