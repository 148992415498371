/**
 * Created by davidcarlson on 2017-01-16.
 */
import React from 'react'
import {Route, Router, Switch} from 'react-router-dom'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import theme from '../assets/teoriappar-theme'
import App from './app/App'
import history from './history'
import {Provider} from 'react-redux'
import store from './store'
import Login from './login/Login'

class Routes extends React.Component {
	render() {
		return (
			<MuiThemeProvider theme={createMuiTheme(theme)}>
				<Provider store={store}>
					<Router history={history}>
						<Switch>
							<Route exact path="/login" component={Login}/>
							<Route path="/" component={App}/>
						</Switch>
					</Router>
				</Provider>
			</MuiThemeProvider>
		)
	}
}

export default Routes