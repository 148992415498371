/**
 * Created by davidcarlson on 2017-01-03.
 */
import {DISABLE_BUTTON, ENABLE_BUTTON, PROVIDER_SIGNIN, SUBMIT_LOGIN, UNAUTH_USER,} from './constants'

import {AUTH_ERROR, CLOSE_SNACKBAR, CONFIRM_CREATE, SAVE_AUTH, USER_CREATED} from './actions'

const initialState = {
	auth: false,
	userCreated: false,
	error: '',
	canSubmit: false,
	resetDrawerOpen: false,
	autoHideDuration: 4000,
	message: '',
	snackBarOpen: false,
}

function app(state = initialState, action) {
	switch (action.type) {
		case SAVE_AUTH:
			return {
				...state,
				auth: true,
				user: action.authResp,
			}
		case UNAUTH_USER:
			return {
				...state,
				auth: false,
			}
		case AUTH_ERROR:
			return {
				...state,
				snackBarOpen: true,
				error: action.error
			}
		case CLOSE_SNACKBAR:
			return {
				...state,
				snackBarOpen: action.isOpen
			}
		case ENABLE_BUTTON:
			return {
				...state,
				canSubmit: true
			}
		case DISABLE_BUTTON:
			return {
				...state,
				canSubmit: false
			}
		case SUBMIT_LOGIN:
			return {
				...state,
				canSubmit: false
			}
		case PROVIDER_SIGNIN:
			return {
				...state,
				canSubmit: false
			}
		case USER_CREATED:
			return {
				...state,
				userCreated: true
			}
		case CONFIRM_CREATE:
			return {
				...state,
				userCreated: false
			}
		default:
			return state
	}
}

export default app