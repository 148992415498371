import React from 'react'
import {grey,} from '@material-ui/core/colors'
import {
  Typography
} from '@material-ui/core'

export const SmallerTitle = ({text = null, ...rest}) => <Typography {...rest} color="secondary"
                                                                    variant="caption">{text === null ? rest.children : text}</Typography>
export const SmallTitle = ({text = null, ...rest}) => <Typography style={{fontSize: '14px'}} color="secondary" {...rest}
                                                                  variant="caption">{text === null ? rest.children : text}</Typography>

export const TextTitle = ({text = null, fontSize = 16, fontWeight = 400, color = grey[800], style, ...rest}) =>
  <Typography
    style={{
      color,
      fontSize,
      fontWeight, ...style
    }} {...rest}>{text === null ? rest.children : text}</Typography>