import React from 'react'
import * as PropTypes from 'prop-types'
import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'Index'
	},
]

const ignoreAttrs = ['Deleted', 'Done']

const Checklists = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'checklists'}
		listSearchQuery={q => `ID:${q} OR Body:${q} OR Title:${q} OR Index:${q}`}
		downloadJsonUrl={`/Country/${country_id}/App/${app_id}/Checklists?full=true&latest=true`}
		csvUrl={`/Country/${country_id}/App/${app_id}/CheckList/Csv`}
		versionUrl={`/Country/${country_id}/App/${app_id}/Version/CheckList`}
		{...{ignoreAttrs, sortOpts}}
		{...rest}/>

Checklists.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Checklists