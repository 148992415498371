/**
 * Created by davidcarlson on 2017-01-03.
 */

export const IS_LOADING = 'IS_LOADING'

export function isLoading(bool) {
	return function () {
		return {
			type: IS_LOADING,
			isLoading: bool
		}
	}
}

export const NOTIFY_ERROR = 'NOTIFY_ERROR'

export function notifyAppError(error) {
	console.error(error)

	return {
		type: NOTIFY_ERROR,
		error
	}
}

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'

export function closeSnackBar() {
	return {
		type: CLOSE_SNACKBAR
	}
}

export function getInitData(token) {

}