import React from 'react'
import * as PropTypes from 'prop-types'
import {TextField} from '@material-ui/core'
import helpers from '../../helpers'

const ObjectInput = ({value, onChange, properties, ...rest}) => {
	return Object.keys(properties).map((key, i) => <TextField {...rest} key={i} multiline label={key} fullWidth value={value[key] || ''} onChange={e => {
		value[key] = helpers.getAddedValue(e.target.value, properties[key].type)
		onChange(value)
	}}/>)
}

ObjectInput.propTypes = {
	value: PropTypes.object,
	onChange: PropTypes.func,
	properties: PropTypes.object,
}

export default ObjectInput
