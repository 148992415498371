import React from 'react'
import * as PropTypes from 'prop-types'
import {
	DialogContent,
	DialogTitle,
	DialogActions,
	Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, TextField
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import AddIcon from '@material-ui/icons/Add'
import ObjectInput from './ObjectInput'
import helpers from '../../helpers'

const DefaultInput = ({value, onChange, ...rest}) => <TextField fullWidth onChange={e => onChange(e.target.value)} value={value} {...rest}/>

const inputs = {
	'integer': DefaultInput,
	'string': DefaultInput,
	'object': ObjectInput,
}

class ArrayUpdateDialog extends React.Component {
	constructor(props) {
		super(props)
		const {value = [], items: {type}} = props
		this.state = {
			value,
			newValue: type === 'object' ? {}: '',
		}
	}

	removeItem = i => {
		const {value} = this.state
		value.splice(i, 1)
		this.setState({value})
	}

	addItem = () => {
		const {items: {type}} = this.props
		const {value, newValue} = this.state
		value.push(helpers.getAddedValue(newValue, type))
		this.setState({value, newValue: type === 'object' ? {}: ''})
	}

	changeItem = (v, i) => {
		let {value} = this.state
		value[i] = v
		this.setState({value})
	}

	render() {
		const {close, update, title, link, items} = this.props
		const {value, newValue} = this.state
		const UpdateInput = inputs[items.type]
		return (
			<React.Fragment>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<List>
						{value.map((e, i) =>
							<ListItem key={i}>
								<ListItemText><UpdateInput properties={items.properties} value={e} onChange={value => this.changeItem(value, i)}/></ListItemText>
								<ListItemSecondaryAction>
									<IconButton
										onClick={() => this.removeItem(i)}
										children={<DeleteIcon/>}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						)}
						<ListItem key={value.length + 1}>
							<ListItemText><UpdateInput placeholder={'Add item'} properties={items.properties} value={newValue} onChange={newValue => this.setState({newValue})}/></ListItemText>
							<ListItemSecondaryAction>
								<IconButton
									onClick={this.addItem}
									children={<AddIcon/>}
								/>
							</ListItemSecondaryAction>
						</ListItem>
					</List>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={close} variant={'contained'} size='small'>
						Cancel
					</Button>
					<Button color="primary" onClick={() => update({[title]: value}, link)} variant={'contained'} size='small'>
						Update
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}
}

ArrayUpdateDialog.propTypes = {
	link: PropTypes.object,
	items: PropTypes.object,
	value: PropTypes.array,
	title: PropTypes.string,
	close: PropTypes.func,
	update: PropTypes.func,
}

export default ArrayUpdateDialog