import React from 'react'
import PropTypes from 'prop-types'
import SaveIcon from '@material-ui/icons/Save'
import PublishedIcon from '@material-ui/icons/Bookmark'
import UnPublishedIcon from '@material-ui/icons/BookmarkBorder'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import {Button,} from '@material-ui/core'

import {CREATE, DELETE} from '../client/Client'

const ActionToolbar = props =>
	<div style={{
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: 10,
		paddingBottom: 10,
	}}>
		{props.id !== CREATE && <Button
			variant="contained" color="secondary"
			onClick={e => {
				//eslint-disable-next-line
				const remove = confirm('Are you sure you wanna remove this item?')
				if (remove) {
					props.handleRequest(DELETE)
				} else {
					e.preventDefault()
				}
			}}
		>Delete&nbsp;<DeleteIcon/></Button>}

		<div>
			{props.published !== undefined && <Button
				style={{marginRight: 10}}
				variant="contained" color="default"
				onClick={props.publishedOnClick}
			>{props.published ? <PublishedIcon/> : <UnPublishedIcon/>}
				Published</Button>}
			<Button
				variant="contained" color="primary"
				type="submit"
			>Save&nbsp;<SaveIcon/></Button>
		</div>
	</div>

ActionToolbar.propTypes = {
	publishedOnClick: PropTypes.func,
	handleRequest: PropTypes.func,
	published: PropTypes.bool,
}

export default ActionToolbar