import React from 'react'
import PropTypes from 'prop-types'
import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'Index'
	},
	{
		name: 'Category',
		expr: 'Category'
	},
	{
		name: 'Description',
		expr: 'Description'
	}
]

const ignoreAttrs = ['Deleted', 'Done', 'QuestionStatus', 'Favourite', 'AnswerQuestionDuration', 'Points', 'PointsCategory', 'ParentQuestion']

const headersSort = (a, b) => {
	if (a === 'ID') return -1
	if (b === 'ID') return 1
	const sortArr = ['ID', 'Index', 'Question', 'Answers', 'Description', 'Category', 'Language', 'CorrectAnswerIndex', 'TheorySections', 'ShouldMixAnswers', 'Published', 'UpdatedAt']
	return sortArr.indexOf(a) - sortArr.indexOf(b)
}

const Questions = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'questions'}
		listSearchQuery={q => `ID:${q} OR Question:${q} OR Category:${q} OR Index:${q}`}
		csvUrl={`/Country/${country_id}/App/${app_id}/Question/Csv`}
		downloadJsonUrl={`/Country/${country_id}/App/${app_id}/Questions?full=true&latest=true`}
		versionUrl={`/Country/${country_id}/App/${app_id}/Version/Question`}
		to={id => `/country/${country_id}/app/${app_id}/question/${id}`}
		{...{ignoreAttrs, sortOpts, headersSort}}
		{...rest}/>

Questions.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Questions