/**
 * Created by davidcarlson on 2017-01-03.
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'
import {Avatar, Grow, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Paper, TextField} from '@material-ui/core'

import {LevelTypes} from './constants'
import helpers from '../helpers'
import '../../../assets/stylesheet/components.css'
import questionImg from '../../../assets/img/levels/levelsquestions.png'
import quizImg from '../../../assets/img/levels/levelsquiz.png'
import roadsignImg from '../../../assets/img/levels/levelsroadsigns.png'
import theoryImg from '../../../assets/img/levels/levelstheory.png'
import videoImg from '../../../assets/img/levels/levelsvideo.png'
import storyImg from '../../../assets/img/levels/levelsstory.png'

import {grey,} from '@material-ui/core/colors'

import {CREATE, UPDATE} from '../../client/Client'
import ActionToolbar from '../ActionToolbar'

class Level extends Component {
	constructor(props) {
		super(props)
		const {country_id, app_id, level_id} = props.match.params
		this.handleEditorChange = this.handleEditorChange.bind(this)
		this.state = {
			countryID: country_id,
			appID: app_id,
			ID: level_id,
			element: level_id === CREATE ? {
				Properties: {}
			} : undefined,
			LevelItems: undefined,
		}
		this.submitForm = this.submitForm.bind(this)
	}

	handleEditorChange(e) {
		let temp = this.state.element
		temp.Properties.HtmlString = e.target.getContent()
		this.setState({element: temp})
	}

	submitForm(e) {
		this.handleRequest(this.state.ID === CREATE ? this.state.ID : UPDATE, this.state.element.Properties)
		e.preventDefault()
	}

	getLevelItems = (i, token) => {
		if (!token) {
			token = this.props.user.user.Token
		}
		const url = helpers.getURL(this.state.element, LevelTypes[i].toLowerCase())
		if (url) {
			this.props.doRequest(token, url).then(res => {
				if (res && res.data) {
					this.setState({LevelItems: res.data.Properties.Elements})
				} else if (res.refreshToken) {
					try {
						firebase.initializeApp(credentials)
					} catch (e) {
						console.warn('firebase app already created')
					}
					firebase.auth().onAuthStateChanged(user => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: user.Fd})
						this.getLevelItems(i, user.Fd)
					})
				}
			})
		} else {
			this.setState({LevelItems: []})
		}
	}

	componentDidMount() {
		this.callClient()
	}

	setLinks = data => {
		if (data) {
			for (let link of data.Links) {
				this.setState({[link.Rel]: link})
			}
		}
	}

	callClient = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		if (this.state.ID === CREATE) {
			return
		}
		const link = {
			Method: 'GET',
			URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Level/${this.state.ID}`
		}
		this.props.doRequest(token, link).then(res => {
			if (res && res.data) {
				this.setLinks(res.data)
				this.setState({element: res.data})
				this.getLevelItems(res.data.Properties.Type)
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	handleRequest = (action, data, token) => {
		if (!token) {
			token = this.props.user.user.Token
		}
		let url
		switch (action) {
			case CREATE:
				url = {
					URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Levels`,
					Method: 'POST'
				}
				break
			default:
				url = helpers.getURL(this.state.element, action)
				break
		}
		if (url) {
			this.props.doRequest(token, url, '', 0, '', data).then(res => {
				if (res && res.data) {
					this.setState({element: res.data})
				} else if (res.refreshToken) {
					try {
						firebase.initializeApp(credentials)
					} catch (e) {
						console.warn('firebase app already created')
					}
					firebase.auth().onAuthStateChanged(user => {
						user.getIdToken(true).then(token => {
							this.props.saveUser({
								ID: user.uid,
								Email: user.email,
								Name: user.displayName,
								Token: token
							})
							this.handleRequest(action, data, token)
						})
					})
				}
			})
		}
	}

	menuItem = (name, i) => <MenuItem value={i} key={i}>
		<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><Avatar
			src={this.getLevelImg(i)}/>&nbsp;{name}</div>
	</MenuItem>

	getLevelImg = i => {
		switch (i) {
			case 1:
				return theoryImg
			case 2:
				return questionImg
			case 3:
				return quizImg
			case 4:
				return quizImg
			case 5:
				return storyImg
			case 6:
				return roadsignImg
			case 8:
				return videoImg
			default:
				return null
		}
	}

	textField = (v, multiline) => <TextField
		name={v}
		label={v}
		style={{margin: 5}}
		value={this.state.element.Properties[v]}
		fullWidth={true}
		multiline={multiline}
		variant="outlined"
		margin="normal"
		onChange={e => {
			let temp = this.state.element
			temp.Properties[v] = e.target.value
			this.setState({element: temp})
		}}
	/>

	elementField = () => <TextField
		name="Element"
		label="Element"
		style={{margin: 5}}
		value={JSON.stringify(this.state.element.Properties, null, 2)}
		fullWidth
		multiline
		disabled
		variant="outlined"
		margin="normal"
		onChange={e => {
			let temp = this.state.element
			temp.Properties = JSON.parse(e.target.value)
			this.setState({element: temp})
		}}
	/>

	numberField = v => <TextField
		style={{margin: 5}}
		name={v}
		label={v}
		value={this.state.element.Properties[v]}
		fullWidth={true}
		required
		margin="normal"
		type="number"
		variant="outlined"
		onChange={e => {
			let temp = this.state.element
			temp.Properties[v] = parseInt(e.target.value, 10)
			this.setState({element: temp})
		}}
	/>

	render() {
		const {element} = this.state
		return (
			<div className="entity-container">
				{element && <div>
					<form onSubmit={this.submitForm} autoComplete="off">
						<ActionToolbar
							id={this.state.ID}
							handleRequest={this.handleRequest}
						/>
						<Grow in={!!element}>
							<Paper>
								<div style={{display: 'flex', flexDirection: 'column', flexFlow: 'column'}}>
									<div style={{padding: 19}}>
										<div className="flex-row full-width">
											<TextField
												label="Type"
												select
												margin="normal"
												variant="outlined"
												value={element.Properties.Type}
												style={{margin: 5}}
												fullWidth
												required
												onChange={e => {
													this.getLevelItems(e.target.value)
													let temp = element
													temp.Properties.Type = e.target.value
													this.setState({element: temp})
												}}
											>
												{LevelTypes.map((levelName, index) => {
													switch (index) {
														case 1:
															return this.menuItem(levelName, index)
														case 2:
															return this.menuItem(levelName, index)
														case 3:
															return this.menuItem(levelName, index)
														case 4:
															return this.menuItem(levelName, index)
														case 5:
															return this.menuItem(levelName, index)
														case 6:
															return this.menuItem(levelName, index)
														case 8:
															return this.menuItem(levelName, index)
														default:
															return null
													}
												})}
											</TextField>
										</div>
										<div className="flex-row full-width">
											{this.numberField('LevelIndex')}
											{this.numberField('ChapterIndex')}
										</div>
										<div className="flex-row full-width">
											{this.textField('Title')}
											{this.textField('HeaderTitle')}
										</div>
										<div className="flex-row full-width">
											{this.textField('IntroText', true)}
										</div>
										<div className="flex-row full-width">
											{this.textField('SummaryText', true)}
										</div>
										<div className="flex-row full-width">
											{this.elementField('element')}
										</div>
									</div>
								</div>
							</Paper>
						</Grow>
						<Grow in={this.state.LevelItems && this.state.LevelItems.length > 0}>
							<Paper style={{marginTop: 10}}>
								<List>
									{this.state.LevelItems && this.state.LevelItems.map((row, index) => {
										return (
											<ListItem key={index}>
												<ListItemAvatar>
													<Avatar
														size={40}
														style={{backgroundColor: grey[300]}}
														src={row.Properties.Sources && row.Properties.Sources.length > 0 ? row.Properties.Sources[0].URL : null}
													>{row.Properties.Sources && row.Properties.Sources.length > 0 ? null : row.Properties.Category && row.Properties.Category.substring(0, 1)}</Avatar>
												</ListItemAvatar>
												<ListItemText
													primaryTypographyProps={{
														style: {
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis',
															overflow: 'hidden'
														}
													}}
													primary={row.Properties.Question ? `${row.Properties.Question.substring(0, 35)} ...` : row.Name}
													secondary={`${row.Properties.Category}`}
												/>

											</ListItem>
										)
									})}
								</List>
							</Paper>
						</Grow>
					</form>
				</div>}
			</div>
		)
	}
}

Level.propTypes = {
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	routeProps: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	saveUser: PropTypes.func,
}

export default Level
