/**
 * Created by davidcarlson on 2017-01-03.
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'
import helpers from '../helpers'

import CancelIcon from '@material-ui/icons/Clear'
import PhotoIcon from '@material-ui/icons/AddAPhoto'

import {Grow, Paper, TextField} from '@material-ui/core'

import {grey,} from '@material-ui/core/colors'

import {CREATE} from '../../client/Client'
import ActionToolbar from '../ActionToolbar'

class RoadSign extends Component {
	constructor(props) {
		super()
		const {country_id, app_id, roadsign_id} = props.match.params
		this.state = {
			countryID: country_id,
			appID: app_id,
			ID: roadsign_id,
			element: roadsign_id === CREATE ? {
				Properties: {}
			} : undefined,
			imageList: undefined
		}
		this.submitForm = this.submitForm.bind(this)
	}

	submitForm(e) {
		//this.handleRequest(this.state.ID === CREATE ? this.state.ID : UPDATE, temp);
		e.preventDefault()
	}

	componentDidMount() {
		this.callClient()
	}

	setLinks = data => {
		if (data) {
			for (let link of data.Links) {
				this.setState({[link.Rel]: link})
			}
		}
	}

	callClient = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		if (this.state.ID === CREATE) {
			return
		}
		const link = {
			Method: 'GET',
			URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/RoadSign/${this.state.ID}`
		}
		this.props.doRequest(token, link).then(res => {
			if (res && res.data) {
				this.setLinks(res.data)
				this.setState({element: res.data})
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	handleRequest = (action, data, token) => {
		if (!token) {
			token = this.props.user.user.Token
		}
		let url
		switch (action) {
			case CREATE:
				url = {
					URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/RoadSigns`,
					Method: 'POST'
				}
				break
			default:
				url = helpers.getURL(this.state.element, action)
				break
		}
		if (url) {
			this.props.doRequest(token, url, '', 0, '', data).then(res => {
				if (res && res.data) {
					this.setState({element: res.data})
				} else if (res.refreshToken) {
					try {
						firebase.initializeApp(credentials)
					} catch (e) {
						console.warn('firebase app already created')
					}
					firebase.auth().onAuthStateChanged(user => {
						user.getIdToken(true).then(token => {
							this.props.saveUser({
								ID: user.uid,
								Email: user.email,
								Name: user.displayName,
								Token: token
							})
							this.handleRequest(action, data, token)
						})
					})
				}
			})
		}
	}

	removeImage = i => {
		if (i === 0) {

		} else {
			let array = this.state.element.Properties.SubImages
			array.splice(i - 1, 1)
			let p = this.state.element
			p.Properties.SubImages = array
			this.setState({element: p})
		}
	}

	addImage = i => {
		if (i === 0) {

		} else {
			let array = this.state.element.Properties.SubImages
			array.push()
			let p = this.state.element
			p.Properties.SubImages = array
			this.setState({element: p})
		}
	}

	handleFileChange(e) {
		let reader = new FileReader()
		let file = e.target.files[0]

		if (file !== undefined) {
			reader.onloadend = () => {
				this.setState({
					file: file,
					QuestionImage: reader.result
				})
			}

			reader.readAsDataURL(file)
		}
	};

	renderImage = (url, index) =>
		<Grow in={!!this.state.element}>
			<div style={{width: '100%', height: 300}}>
				<Paper
					style={{
						cursor: 'pointer',
						width: '100%',
						height: '100%',
					}}
				>
					<div style={{
						height: '100%',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						backgroundImage: url && url.length > 0 ? 'url(' + url + ')' : null,
						backgroundSize: 'cover',
						backgroundPosition: 'center center',
						backgroundRepeat: 'no-repeat'
					}}>
						<div style={{width: '20%'}}/>
						<div style={{
							display: 'flex',
							height: '100%',
							justifyContent: 'center',
							width: '80%'
						}}>
							<PhotoIcon
								onClick={() => this.refs[`${index}-fileinput`].click()}
								style={{
									alignSelf: 'center',
									width: 60,
									color: url && url.length > 0 ? 'rgba(255, 255, 255, 0.3)' : grey[400],
									height: 60,
								}}/>
						</div>
						<div style={{
							display: 'flex',
							width: '20%',
							justifyContent: 'flex-end',
						}}>
							{url && url.length > 0 && <CancelIcon
								onClick={() => this.removeImage(index)}
								className="img-clear-btn"
								style={{
									width: 40,
									height: 40,
								}}
							/>}
						</div>
					</div>

				</Paper>
				<input
					type="file"
					id="upload"
					ref={`${index}-fileinput`}
					accept="image/x-png,image/jpeg"
					className="hidden"
					onChange={(e) => this.handleFileChange(e)}
				/>
			</div>
		</Grow>

	textField = (v, multiline) => <TextField
		name={v}
		label={v}
		style={{margin: 5}}
		value={this.state.element.Properties[v]}
		fullWidth={true}
		required
		multiline={multiline}
		variant="outlined"
		margin="normal"
		onChange={e => {
			let temp = this.state.element
			temp.Properties[v] = e.target.value
			this.setState({element: temp})
		}}
	/>

	render() {
		const {element} = this.state
		return (
			<div className="entity-container">
				{element && <div>
					<form onSubmit={this.submitForm} autoComplete="off">
						<ActionToolbar
							id={this.state.ID}
							handleRequest={this.handleRequest}
						/>
						<Grow in={!!element}>
							<Paper>
								<div style={{padding: 19}}>
									<div>
										<div>
											{this.textField('Name', false)}
										</div>
										<div>
											{this.textField('MoreInfo', true)}
										</div>
									</div>
								</div>
							</Paper>
						</Grow>
						<div className="full-width" style={{marginTop: 10}}>
							{element.Properties && element.Properties.PrimaryImage && this.renderImage(element.Properties.PrimaryImage.URL, 0)}
						</div>
						{element.Properties && element.Properties.SubImages && element.Properties.SubImages.map((source, index) =>
							<div
								key={index} className="full-width" style={{marginTop: 10}}>
								{this.renderImage(source.URL, index + 1)}
							</div>)}
						<div className="full-width" style={{marginTop: 10}}>
							{element.Properties && element.Properties.SubImages && this.renderImage('', element.Properties.SubImages.length + 2)}
						</div>
					</form>
				</div>}
			</div>
		)
	}
}

RoadSign.propTypes = {
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	routeProps: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	saveUser: PropTypes.func,
}

export default RoadSign
