/**
 * Created by davidcarlson on 2017-04-05.
 */

import * as credentials from '../firebase/credentials'

class Helpers {

	static getAddedValue = (value, type) => {
		switch (type) {
			case 'integer':
				return parseInt(value, 10)
			default:
				return value
		}
	}

	static getLinks = data => {
		let p = {}
		if (data) {
			for (let link of data.Links) {
				p[link.Rel] = link
			}
		}
		return p
	}

	static renderAmount(amount, currency) {
		return `${parseFloat(Math.round(amount) / 100).toFixed(2)} ${currency}`
	}

	static getURL = (app, kind) => {
		if (kind === 'users') {
			return {
				Rel: 'users',
				Method: 'GET',
				URL: `https://${credentials.storageBucket}/Users`
			}
		}
		return app && app.Links.filter(link => {
			return link.Rel === kind
		})[0]
	}
}

export default Helpers