/**
 * Created by davidcarlson on 2017-01-03.
 */

import {GET_APPS, SAVE_APP, SAVE_APPS} from './actions'

const initialState = {
	apps: undefined,
	selectedApp: undefined,
}

function apps(state = initialState, action) {
	switch (action.type) {
		case GET_APPS:
			return Object.assign({}, state, {
				isLoading: true,
			})
		case SAVE_APPS:
			return {
				...state,
				apps: action.apps,
			}
		case SAVE_APP:
			return {
				...state,
				selectedApp: action.selectedApp,
			}
		default:
			return state
	}
}

export default apps