/**
 * Created by davidcarlson on 2017-01-03.
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Avatar, Grow, List, ListItem, ListItemAvatar, ListItemText, Paper} from '@material-ui/core'
import {debounce} from 'throttle-debounce'
import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'
import AudioIcon from '@material-ui/icons/AudiotrackTwoTone'
import {grey,} from '@material-ui/core/colors'
import ListToolbar from '../list/ListToolbar'

const grey300 = grey[300]

class Sources extends Component {
	constructor(props) {
		super(props)
		const {country_id, app_id} = props.match.params
		this.state = {
			search: '',
			countryID: country_id,
			appID: app_id,
			type: 'image',
			limit: 10,
			elements: undefined,
			componentName: 'Sources',
		}
		this.callClient = debounce(1000, this.callClient)
	}

	componentDidMount() {
		this.callClient()
	}

	filterElements = e => {
		const a = e.target.value
		if (a && a.length > 0) {
			this.setState({search: `${a}`})
			this.callClient()
		} else if (this.state.search && this.state.search.length > 0) {
			this.setState({search: ''})
			this.callClient()
		}
	}

	handleOptValue = (n, v) => {
		this.setState({[n]: v, elements: undefined})
		this.callClient()
	}

	callClient = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		const link = {
			Method: 'GET',
			URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Sources`
		}
		this.props.doRequest(token, link, undefined, this.state.limit, undefined, undefined, {
			prefix: this.state.search,
			type: this.state.type
		}).then(res => {
			if (res && res.data) {
				this.setState({elements: res.data.Properties.Elements})
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	render() {
		const {maxWidth = 700, onListItemClick = null} = this.props
		return (
			<div style={{
				display: 'inline-block',
				height: '100%',
				width: '100%',
				minWidth: 560,
				maxWidth
			}}>
				<ListToolbar
					componentName={this.state.componentName}
					filterElements={this.filterElements}
					handleOptValue={this.handleOptValue}
					opts={[
						{
							name: 'type',
							selected: this.state.type,
							range: [
								{name: 'Image', value: 'image'},
								{name: 'Video', value: 'video'},
								{name: 'Audio', value: 'audio'}
							]
						},
						{
							name: 'limit',
							selected: this.state.limit,
							range: [
								{name: '10', value: 10},
								{name: '20', value: 20},
								{name: '100', value: 100}
							]
						}
					]}
				/>
				<div>
					{!!this.state.elements ? !!this.state.elements.length > 0 ? <Grow in={this.state.elements.length > 0}>
							<Paper>
								<List>
									{
										this.state.elements.map((row, index) =>
											<ListItem
												key={`li-${index}`}
												style={{cursor: onListItemClick !== null && 'pointer'}}
												onClick={() => onListItemClick !== null ? onListItemClick(row) : {}}
											>
												<ListItemAvatar>
													<Avatar
														style={{backgroundColor: grey300}}
														size={40}
														src={this.state.type === 'image' && row.Properties.URL && row.Properties.URL.length > 0 ? row.Properties.URL : null}
													>{this.state.type === 'audio' ? <AudioIcon/> : null}</Avatar>
												</ListItemAvatar>
												<ListItemText
													primaryTypographyProps={{
														style: {
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis',
															overflow: 'hidden'
														}
													}}
													primary={`${row.Properties.Name}`}
												/>
											</ListItem>
										)}
								</List>
							</Paper>
						</Grow>
						: this.state.elements.length === 0 && <div
							style={{
								textTransform: 'uppercase',
								height: 100,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '40px 0px'
							}}
						>
							<h4>{`No ${this.state.componentName} created yet`}</h4>
						</div>

						: null
					}
				</div>
			</div>
		)
	}
}

Sources.propTypes = {
	user: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	onListItemClick: PropTypes.func,
	saveUser: PropTypes.func,
}

export default Sources
