/**
 * Created by davidcarlson on 2017-01-03.
 */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AddIcon from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import PhotoIcon from '@material-ui/icons/AddAPhoto'

import firebase from 'firebase/app'
import 'firebase/auth'
import credentials from '../../firebase/credentials'

import helpers from '../helpers'
import {Avatar, Grow, IconButton, InputAdornment, List, ListItem, Paper, TextField,} from '@material-ui/core'

import {grey,} from '@material-ui/core/colors'
import {CREATE, UPDATE} from '../../client/Client'
import ActionToolbar from '../ActionToolbar'
import SourceDialog from './SourceDialog'

const fullWhite = '#fff'
const grey100 = grey[100]
const grey400 = grey[400]

class Question extends Component {
	constructor(props) {
		super(props)
		const {country_id, app_id, question_id} = props.match.params
		this.state = {
			countryID: country_id,
			appID: app_id,
			ID: question_id,
			element: question_id === CREATE ? {
				Properties: {
					Published: false,
					Answers: [],
					Sources: undefined,
					Question: undefined,
					CorrectAnswerIndex: [0],
				}
			} : undefined,
			newAnswer: '',
			QuestionImage: undefined,
			file: undefined,
			dialogOpen: false,
		}
		this.submitForm = this.submitForm.bind(this)
	}

	setLinks = data => {
		if (data) {
			for (let link of data.Links) {
				this.setState({[link.Rel]: link})
			}
		}
	}

	closeDialog = () => {
		this.setState({dialogOpen: false})
	}

	openDialog = () => {
		this.setState({dialogOpen: true})
	}

	setPublished() {
		let temp = this.state.element
		temp.Properties.Published = !temp.Properties.Published
		this.setState({
			element: temp,
		})
	};

	callClient = token => {
		if (!token) {
			token = this.props.user.user.Token
		}
		if (this.state.ID === CREATE) {
			return
		}
		const link = {
			Method: 'GET',
			URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Question/${this.state.ID}`
		}
		this.props.doRequest(token, link).then(res => {
			if (res && res.data) {
				this.setLinks(res.data)
				this.setState({element: res.data})
			} else if (res.refreshToken) {
				try {
					firebase.initializeApp(credentials)
				} catch (e) {
					console.warn('firebase app already created')
				}
				firebase.auth().onAuthStateChanged(user => {
					user.getIdToken(true).then(token => {
						this.props.saveUser({ID: user.uid, Email: user.email, Name: user.displayName, Token: token})
						this.callClient(token)
					})
				})
			}
		})
	}

	handleFileChange(e) {
		let reader = new FileReader()
		let file = e.target.files[0]

		if (file !== undefined) {
			reader.onloadend = () => {
				this.setState({
					file: file,
					QuestionImage: reader.result
				})
			}

			reader.readAsDataURL(file)
		}
	};

	componentDidMount() {
		this.callClient()
	}

	submitForm(e) {
		let temp = this.state.element.Properties
		if (this.state.file) {
			temp.sourceFile = this.state.file
			temp.sourceURL = `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Question/${this.state.ID}/Sources`
		}
		this.handleRequest(this.state.ID === CREATE ? this.state.ID : UPDATE, temp)
		e.preventDefault()
	}

	removeAnswerOption(i) {
		let temp = this.state.element
		let array = temp.Properties.Answers
		array.splice(i, 1)
		temp.Properties.Answers = array
		this.setState({element: temp})
	}

	addAnswerOption() {
		let temp = this.state.element
		let array = temp.Properties.Answers
		if (this.state.newAnswer.length > 0) {
			array.push(this.state.newAnswer)
			temp.Properties.Answers = array
			this.setState({element: temp, newAnswer: ''})
		}
	}

	setCorrectAnswerIndex(i) {
		let temp = this.state.element
		let array = temp.Properties.CorrectAnswerIndex
		let t = array.indexOf(i)
		if (t !== -1) {
			array.splice(t, 1)
		} else {
			array.push(i)
		}
		array = array.sort((a, b) => a - b)
		temp.Properties.CorrectAnswerIndex = array
		this.setState({element: temp})
	}

	getLetter = index => {
		return String.fromCharCode(65 + index)
	}

	setSource = row => {
		let temp = this.state.element
		temp.Properties.Sources = []
		temp.Properties.Sources.push(row.Properties)
		this.setState({element: temp, QuestionImage: undefined})
	}

	removeSource = () => {
		let temp = this.state.element
		temp.Properties.Sources = undefined
		this.setState({element: temp, QuestionImage: undefined})
	}

	handleRequest = (action, data, token) => {
		if (!token) {
			token = this.props.user.user.Token
		}
		let url
		switch (action) {
			case CREATE:
				url = {
					URL: `https://${credentials.storageBucket}/Country/${this.state.countryID}/App/${this.state.appID}/Questions`,
					Method: 'POST'
				}
				break
			default:
				url = helpers.getURL(this.state.element, action)
				break
		}
		if (url) {
			this.props.doRequest(token, url, '', 0, '', data).then(res => {
				if (res && res.data) {
					this.setState({element: res.data})
				} else if (res.refreshToken) {
					try {
						firebase.initializeApp(credentials)
					} catch (e) {
						console.warn('firebase app already created')
					}
					firebase.auth().onAuthStateChanged(user => {
						user.getIdToken(true).then(token => {
							this.props.saveUser({
								ID: user.uid,
								Email: user.email,
								Name: user.displayName,
								Token: token
							})
							this.handleRequest(action, data, token)
						})
					})
				}
			})
		}
	}

	textField = v => <TextField
		name={v}
		label={v}
		value={this.state.element.Properties[v]}
		fullWidth
		multiline
		required
		variant="outlined"
		onChange={e => {
			let temp = this.state.element
			temp.Properties[v] = e.target.value
			this.setState({element: temp})
		}}
	/>

	render() {
		return (
			<div className="entity-container">
				{this.state.element && <div>
					<form onSubmit={this.submitForm} autoComplete="off">
						<ActionToolbar
							id={this.state.ID}
							handleRequest={this.handleRequest}
							publishedOnClick={() => {
								let temp = this.state.element
								temp.Properties.Published = !temp.Properties.Published
								this.setState({
									element: temp,
								})
							}}
							published={this.state.element.Properties.Published}
						/>
						<Grow in={!!this.state.element}>
							<Paper>
								<div style={{display: 'flex', flexDirection: 'column', flexFlow: 'column'}}>
									<List>
										<ListItem key={-2}>
											<div style={{width: '100%', height: 400}}>
												<Paper
													onClick={this.openDialog}
													style={{
														cursor: 'pointer',
														width: '100%',
														height: '100%',
													}}
												>
													<div style={{
														height: '100%',
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														backgroundImage: this.state.QuestionImage ? 'url(' + this.state.QuestionImage + ')' : this.state.element.Properties.Sources && this.state.element.Properties.Sources.length > 0 ? 'url(' + this.state.element.Properties.Sources[0].URL + ')' : null,
														backgroundSize: 'cover',
														backgroundPosition: 'center center',
														backgroundRepeat: 'no-repeat'
													}}>
														<PhotoIcon
															style={{
																color: grey400,
																width: 60,
																height: 60,
															}}/>

													</div>
												</Paper>
											</div>
										</ListItem>
										<ListItem key={-1}>
											{this.textField('Question')}
										</ListItem>
										{this.state.element.Properties.Answers.map((row, index) => {
											return (
												<ListItem key={index}>
													<TextField
														name={`${index}`}
														label={`Answer option ${index + 1}`}
														value={row}
														fullWidth={true}
														InputProps={{
															startAdornment: (
																<InputAdornment position="start">
																	<Avatar
																		onClick={this.setCorrectAnswerIndex.bind(this, index)}
																		style={{
																			cursor: 'pointer',
																			backgroundColor: this.state.element.Properties.CorrectAnswerIndex && this.state.element.Properties.CorrectAnswerIndex.includes(index) ? '#4da944' : grey[100],
																			color: this.state.element.Properties.CorrectAnswerIndex && this.state.element.Properties.CorrectAnswerIndex.includes(index) ? fullWhite : '#FE7569'
																		}}>
																		{this.getLetter(index)}
																	</Avatar>
																</InputAdornment>
															),
															endAdornment: (
																<IconButton
																	onClick={this.removeAnswerOption.bind(this, index)}
																	children={<DeleteIcon/>}
																/>
															)
														}}
														multiline={true}
														id={`answer-${index}`}
														variant="outlined"
														onChange={e => {
															let temp = this.state.element
															temp.Properties.Answers[index] = e.target.value
															this.setState({element: temp})
														}}
													/>
												</ListItem>
											)
										})}
										<ListItem key={this.state.element.Properties.Answers.length + 1}>
											<TextField
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<Avatar style={{
																backgroundColor: grey100,
																color: grey400
															}}>
																{this.getLetter(this.state.element.Properties.Answers.length)}
															</Avatar>
														</InputAdornment>
													),
													endAdornment: (
														<IconButton
															onClick={this.addAnswerOption.bind(this)}
															children={<AddIcon/>}
														/>
													)
												}}
												label="Add your new answer option here"
												placeholder={'Add your new answer option here'}
												onChange={e => {
													this.setState({
														newAnswer: e.target.value,
													})
												}}
												id="newAnswer"
												name="newAnswer"
												fullWidth
												multiline
												value={this.state.newAnswer}
												variant="outlined"
											/>
										</ListItem>
										<ListItem key={this.state.element.Properties.Answers.length + 2}>
											<TextField
												name={'Description'}
												label={'Description'}
												value={this.state.element.Properties.Description}
												fullWidth
												multiline
												variant="outlined"
												onChange={e => {
													const {value} = e.target
													this.setState(state => {
														let temp = Object.assign({}, state.element)
														temp.Properties.Description = value
														return {element: temp, ...state}
													})
												}}
											/>
										</ListItem>
									</List>
								</div>
							</Paper>
						</Grow>
					</form>
				</div>
				}
				{this.state.element && this.state.element.Properties &&
				<SourceDialog {...this.props} onSourceClick={this.setSource} removeSource={this.removeSource}
				              handleFileChange={this.handleFileChange} QuestionImage={this.state.QuestionImage}
				              Sources={this.state.element.Properties.Sources} open={this.state.dialogOpen}
				              onClose={() => this.closeDialog()}/>}
			</div>

		)
	}
}

Question.propTypes = {
	user: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	routeProps: PropTypes.object.isRequired,
	doRequest: PropTypes.func,
	saveUser: PropTypes.func,
}

export default Question
