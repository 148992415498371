export const LevelTypes = [
	'None',
	'Theory',
	'Questions',
	'Quiz',
	'Quiz on wrong answers',
	'Story',
	'Roadsigns',
	'Web',
	'Video',
]