/**
 * Created by davidcarlson on 2017-02-27.
 */
import axios from 'axios'

export const UPDATE = 'update'
export const CREATE = 'create'
export const DELETE = 'delete'

export function uploadCsv(token, url, csvData) {
	let data = new FormData();
	data.append("csv", csvData);
	return axios({
		method: 'post',
		url,
		data,
		headers: {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
			'Accept': 'application/json',
		}
	})
}

export function doRequest(token, link, search, limit, sort, data, attrs) {
	let url = `${link.URL}`
	if (!data) {
		url += (url.indexOf('?') > -1) ? '&search=true' : '?search=true'
	}
	if (limit) {
		url += `&limit=${limit}`
	}
	const cursorExist = url.indexOf('cursor=') > -1
	if (search && search.length > 0 && (search.indexOf('ObjectID') > -1)) {
		let objectId = search.split(':')[1]
		if (objectId && objectId.length > 9) {
			objectId = objectId.trim()
			url += `&objectid=${objectId}`
		}
	} else if (search && search.length > 0 && !cursorExist) {
		url += `&q=${search}`
	}
	if (sort && sort.expr && sort.expr.length > 0) {
		url += `&sort=${sort.expr}`
	}
	if (attrs) {
		for (let p in attrs) {
			if (attrs[p]) {
				url += `&${p}=${attrs[p]}`
			}
		}
	}
	let req = {
		url: url,
		method: link.Method,
		headers: {
			'Authorization': 'Bearer ' + token,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
	}
	if (data) {
		req.data = JSON.stringify(data)
	}
	return axios(req)
}

export function postSource(token, url, imageData) {
	let formData = new FormData()
	formData.append('Image', imageData)

	return axios({
		method: 'post',
		url: url,
		data: formData,
		headers: {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'multipart/form-data',
			'Accept': 'application/json',
		}
	})
}
