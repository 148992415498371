/**
 * Created by davidcarlson on 2017-01-03.
 */

import {CLOSE_SNACKBAR, IS_LOADING, NOTIFY_ERROR} from './actions'

const initialState = {
	notifications: [],
	error: null,
	isLoading: false,
	autoHideDuration: 4000,
	snackBarOpen: false,
	user: undefined
}

function app(state = initialState, action, isLoading = false) {
	switch (action.type) {
		case IS_LOADING:
			return Object.assign({}, state, {
				isLoading: action.isLoading,
			})
		case NOTIFY_ERROR:
			return {
				...state,
				snackBarOpen: true,
				isLoading: false,
				error: action.error
			}
		case CLOSE_SNACKBAR:
			return {
				...state,
				snackBarOpen: false,
				error: null
			}
		default:
			return state
	}
}

export default app