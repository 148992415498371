import React from 'react'
import * as PropTypes from 'prop-types'
import {
	DialogContent,
	DialogTitle,
	DialogActions,
	Button, Switch, Typography
} from '@material-ui/core'

class BooleanUpdateDialog extends React.Component {
	constructor(props) {
		super(props)
		const {value = false} = props
		this.state = {
			value
		}
	}

	render() {
		const {close, update, title, link} = this.props
		const {value} = this.state
		return (
			<React.Fragment>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					<Switch checked={value} onChange={e => this.setState({value: e.target.checked})}/>
					<Typography>{JSON.stringify(value)}</Typography>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={close} variant={'contained'} size='small'>
						Cancel
					</Button>
					<Button color="primary" onClick={() => update({[title]: value}, link)} variant={'contained'} size='small'>
						Update
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}
}

BooleanUpdateDialog.propTypes = {
	link: PropTypes.object,
	value: PropTypes.bool,
	title: PropTypes.string,
	close: PropTypes.func,
	update: PropTypes.func,
}

export default BooleanUpdateDialog