import React from 'react'
import PropTypes from 'prop-types'

import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'Index'
	},
]

const ignoreAttrs = ['Deleted', 'Done']

const Qas = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'qas'}
		listSearchQuery={q => `ID:${q} OR Question:${q} OR Answer:${q} OR Index:${q}`}
		downloadJsonUrl={`/Country/${country_id}/App/${app_id}/QAs?full=true&latest=true`}
		csvUrl={`/Country/${country_id}/App/${app_id}/QA/Csv`}
		versionUrl={`/Country/${country_id}/App/${app_id}/Version/QA`}
		{...{ignoreAttrs, sortOpts}}
		{...rest}/>

Qas.propTypes = {
	match: PropTypes.object.isRequired,
}

export default Qas