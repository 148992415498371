import React from 'react'

import TableComponent from '../table/TableComponent'
const ignoreAttrs = ['Deleted', 'Done', 'CreatedAt', 'DeviceGroupID', 'RegistrationIDs']

const Users = ({...rest}) =>
	<TableComponent
		maxWidth={1400}
		hideLanguage
		entityName={'users'}
		listSearchQuery={q => `ID:${q} OR Email:${q} OR Name:${q}`}
		{...{ignoreAttrs}}
		{...rest}/>

export default Users