import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import UploadIcon from '@material-ui/icons/CloudUpload'
import {Button, Chip, Paper, TextField} from '@material-ui/core'
import ReactFileReader from 'react-file-reader'

import {grey,} from '@material-ui/core/colors'
import VersionTextField from './VersionTextField'
import LanguageSelector from './LanguageSelector'

const grey300 = grey[300]
const fullWhite = '#fff'

class ListToolbar extends Component {

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (this.props.limitOpt !== nextProps.limitOpt) return true
		if (this.props.sortOpt !== nextProps.sortOpt) return true
		return this.props.total !== nextProps.total
	}

	selectLimit(index) {
		this.props.handleSelectLimit(index)
	}

	selectSort(index) {
		this.props.handleSelectSort(index)
	}

	handleOptValue = (n, v) => {
		this.props.handleOptValue(n, v)
	}

	filterElements(e, a, v) {
		this.props.filterElements(e, a, v)
	}

	render() {
		return (
			<Paper style={{
				padding: 10,
				marginTop: 20,
				marginBottom: 10,
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between'
			}}>
				<div style={{display: 'flex', alignItems: 'center'}}>
		      <span style={{height: 24, marginTop: 0, marginRight: '2%', marginLeft: 10}}>
		        <SearchIcon/>
		      </span>
					<TextField
						placeholder={`Search ${this.props.componentName}`}
						className="name-text-field"
						style={{minWidth: 200, marginRight: 10}}
						onChange={this.filterElements.bind(this)}
						autoComplete="off"
					/>
					{this.props.sortOpts && this.props.sortOpts.length > 1 && <div className="p10 flex-row">
						<span className="text-sm-semi-bold mr10" style={{color: grey[400]}}>Sort:</span>
						{this.props.sortOpts.map((sortOpt, index) =>
							<Chip
								key={index}
								style={{
									marginLeft: 5,
									marginRight: 5,
									backgroundColor: this.props.sortOpt === index ? grey300 : null,
									color: this.props.sortOpt === index ? fullWhite : null,
									lineHeight: 2
								}}
								label={sortOpt.name}
								onClick={this.selectSort.bind(this, index)}
							/>
						)}
					</div>}
					{this.props.opts && this.props.opts.map((opt, i) =>
						<div className="p10 flex-row" key={i}>
							<span className="text-sm-semi-bold uppercase mr10">{opt.name}</span>
							{opt.range && opt.range.map((item, index) =>
								<Chip
									key={index}
									style={{
										marginLeft: 5,
										marginRight: 5,
										backgroundColor: opt.selected === item.value ? grey300 : null,
										color: opt.selected === item.value ? fullWhite : null,
										lineHeight: 2
									}}
									label={item.name}
									onClick={() => this.handleOptValue(opt.name, item.value)}
								/>
							)}
						</div>
					)}
					{this.props.total > 0 && <div className="p10 flex-row">
						<span className="text-sm-semi-bold mr10" style={{
							color: grey[400],
							minWidth: 100
						}}>{`Total: ${this.props.total}${this.props.limitOpts[this.props.limitOpt] === this.props.total ? '+' : ''}`}</span>
					</div>}
				</div>
				<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
					{this.props.onChangeLanguage && <LanguageSelector onChange={this.props.onChangeLanguage}/>}
					{this.props.versionUrl && <VersionTextField url={this.props.versionUrl}/>}
					{this.props.downloadJson && <div><Button
						variant="contained"
						color="default"
						size={'small'}
						style={{marginLeft: 10}}
						onClick={this.props.downloadJson}>
						Download JSON&nbsp;<DownloadIcon/>
					</Button></div>}
					{this.props.csvUrl &&
					<React.Fragment>
						<ReactFileReader handleFiles={this.props.uploadCsv} fileTypes={'.csv'}>
							<Button
								variant="contained"
								color="default"
								size={'small'}
								style={{marginLeft: 10}}>
								Upload CSV&nbsp;<UploadIcon/>
							</Button>
						</ReactFileReader>
						<div>
							<Button
								variant="contained"
								color="default"
								size={'small'}
								style={{marginLeft: 10}}
								onClick={this.props.downloadCsv}>
								Download CSV&nbsp;<DownloadIcon/>
							</Button>
						</div>
					</React.Fragment>}
				</div>
			</Paper>
		)
	}
}

ListToolbar.propTypes = {
	handleSelectSort: PropTypes.func,
	openCreateModalDialog: PropTypes.func,
	onChangeLanguage: PropTypes.func,
	handleOptValue: PropTypes.func,
	handleSelectLimit: PropTypes.func,
	filterElements: PropTypes.func,
	sortOpts: PropTypes.array,
	limitOpts: PropTypes.array,
	total: PropTypes.number,
	componentName: PropTypes.string,
	csvUrl: PropTypes.string,
	downloadCsv: PropTypes.func,
	uploadCsv: PropTypes.func,
	downloadJson: PropTypes.func,
}

export default ListToolbar