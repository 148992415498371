/**
 * Created by davidcarlson on 2017-01-03.
 */
import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import {firebaseStateReducer as firebase} from 'react-redux-firebase'
import app from './app/reducer'
import header from './header/reducer'
import login from './login/reducer'
import user from './profile/reducer'
import apps from './apps/reducer'
import client from './client/reducer'

const appReducer = combineReducers({
	app,
	header,
	login,
	firebase,
	user,
	client,
	apps,
	routing:
	routerReducer
})

const rootReducer = (state, action) => {
	if (action.type === 'UNAUTH_USER') {
		state = undefined
	}

	return appReducer(state, action)
}

export default rootReducer