/**
 * Created by davidcarlson on 2017-02-27.
 */
import axios from 'axios'

import {BASE_URL} from '../app/constants'

export function getUser(token) {
	const authHeader = {
		headers: {
			'Authorization': 'Bearer ' + token,
			'Accept': 'application/json'
		}
	}

	return axios.get(BASE_URL, authHeader)
}

export function getRoles(links, token) {
	const authHeader = {
		headers: {
			'Authorization': 'Bearer ' + token,
			'Accept': 'application/json'
		}
	}
	let i = 0
	for (i = 0; i < links.length; i++) {
		if (links[i].Rel === 'roles') {
			break
		}
	}

	return axios.get(links[i].URL, authHeader)
}

export function getDetails(token, userID) {
	return axios({
		method: 'get',
		url: `${BASE_URL}/User/${userID}/Details`,
		headers: {
			'Authorization': 'Bearer ' + token,
			'Accept': 'application/json'
		}
	})
}

export function createDetails(token, orgId, userId, detailsData) {
	return axios({
		method: 'post',
		url: `${BASE_URL}/Org/${orgId}/User/${userId}/Details`,
		data: detailsData,
		headers: {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	})
}

export function updateDetails(token, orgId, userId, detailsId, detailsData) {
	return axios({
		method: 'put',
		url: `${BASE_URL}/Org/${orgId}/User/${userId}/Details`,
		data: detailsData,
		headers: {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	})
}