/**
 * Created by davidcarlson on 2017-01-03.
 */

import * as clientApi from './Client'

export const UPLOAD_CSV = 'UPLOAD_CSV'

export function uploadCsv(token, url, data) {
	return dispatch => {
		dispatch({
			type: 'IS_LOADING',
			isLoading: true
		})
		return clientApi.uploadCsv(token, url, data).then(res => {
			dispatch({
				type: 'IS_LOADING',
				isLoading: false
			})
			res.refreshToken = false
			return res
		}).catch(e => {
			dispatch({
				type: 'IS_LOADING',
				isLoading: false
			})
			console.error(e)
			return {}
		})
	}

}

export const DO_REQUEST = 'DO_REQUEST'

export function doRequest(token, link, search, limit, sort, data, attrs) {
	return (dispatch) => {
		dispatch({
			type: 'IS_LOADING',
			isLoading: true
		})
		return clientApi.doRequest(token, link, search, limit, sort, data, attrs).then(res => {
			if (data && data.sourceFile && data.sourceURL) {
				clientApi.postSource(token, data.sourceURL, data.sourceFile).catch(err => {
					dispatch({
						type: 'NOTIFY_ERROR',
						error: err.toString()
					})
				})
			}
			dispatch({
				type: 'IS_LOADING',
				isLoading: false
			})
			res.refreshToken = false
			return res
		}).catch(e => {
			dispatch({
				type: 'IS_LOADING',
				isLoading: false
			})
			if (e.response.status === 401) {
				return {refreshToken: true}
			}
		})
	}
}
