import React, {Component} from 'react'
import {bindActionCreators} from 'redux'

import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import {withTheme} from '@material-ui/core/styles'

import {connect} from 'react-redux'
import * as Actions from './actions'

import './style.css'

import {FACEBOOK, GOOGLE} from './constants'

import TeoriapparLogo from '../../assets/img/logo.png'

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loginDrawerOpen: false,
			enableButton: false,
			dimensions: null,
		}
	}

	enableButton(bool) {
		this.setState({
			enableButton: bool
		})
	}

	disableButton() {
		this.setState({
			enableButton: false
		})
	}

	openLoginDrawer(bool) {
		this.setState({
			loginDrawerOpen: bool
		})
	}

	openSnackbar(bool) {
		this.props.openSnackbar(bool)
	}

	render() {
		return (
			<div className="login-container">
				<div className="login-paper">
					<div className="center">
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
							}}
							className="full-width"
						>
							<img src={TeoriapparLogo} className="logo" alt="teoriappar logo"/>
						</div>
						<form onSubmit={this.props.submitLoginForm} autoComplete="off">
							<TextField
								name="email"
								required
								fullWidth={true}
								value={this.state.username}
								onChange={(_, v) => this.setState({username: v})}
							/>
							<TextField
								name="password"
								type="password"
								required
								value={this.state.password}
								fullWidth={true}
								onChange={(_, v) => this.setState({password: v})}
							/>
							<div className="submitBtn mt40">
								<Button
									type="submit"
									disabled={!this.state.username || this.state.username.length === 0 || !this.state.password || this.state.password.length === 0}
									fullWidth={true}
								>SIGN IN</Button>
							</div>
							<div style={{
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
							     className="mt10"
							>
								<Button
									label=""
									onClick={this.openLoginDrawer.bind(this, true)}

								><span style={{
									fontSize: '0.75em',
									textDecoration: 'underline',
									textTransform: 'capitalize',
									color: '#4da944'
								}}>Forgot your password?</span></Button>
							</div>
						</form>
						<div className="text-center">

							<div className="or-text">
								<span>or</span>
							</div>
							<div style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								flexWrap: 'wrap'
							}}>
								<Button
									color="primary"
									type="submit"
									onClick={this.props.loginWithProvider.bind(this, FACEBOOK)}
								><span className="fa fa-facebook fa-inverse"/>Sign in with Facebook</Button>
								<Button
									color="primary"
									type="submit"
									onClick={this.props.loginWithProvider.bind(this, GOOGLE)}
								><span className="fa fa-google fa-inverse"/>Sign in with Google</Button>
							</div>
						</div>
					</div>
				</div>
				<Snackbar
					open={this.props.login.snackBarOpen}
					message={this.props.login.error}
					action="close"
					autoHideDuration={this.props.login.autoHideDuration}
					onActionTouchTap={this.openSnackbar.bind(this, false)}
					onRequestClose={this.openSnackbar.bind(this, false)}
				/>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		login: state.login,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(Actions, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Login))