import React, {Component} from 'react'
import PropTypes from 'prop-types'
import history from '../../history'
import InstallationIcon from '@material-ui/icons/SystemUpdate'
import UsersIcon from '@material-ui/icons/People'
import QuestionsIcon from '@material-ui/icons/Assignment'
import PaymentsIcon from '@material-ui/icons/MonetizationOn'
import ChecklistIcon from '@material-ui/icons/CheckCircle'
import WordListIcon from '@material-ui/icons/List'
import QAIcon from '@material-ui/icons/QuestionAnswer'
import VideoIcon from '@material-ui/icons/VideoLibrary'
import PhotoIcon from '@material-ui/icons/Photo'
import TheoryIcon from '@material-ui/icons/LibraryBooks'
import LevelsIcon from '@material-ui/icons/PlayCircleOutline'
import RoadSignIcon from '@material-ui/icons/Traffic'
import AppsIcon from '@material-ui/icons/Apps'
import {Divider, Drawer, ListItemIcon, MenuItem} from '@material-ui/core'
import {TextTitle} from '../../typography/typography'

class MainDrawer extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	handleLocationChange(locationPath) {
		history.push(locationPath)
		this.props.closeMainDrawer()
	};

	isCurrentPath(menuName) {
		return (this.props.currPath.toLowerCase().localeCompare(menuName.toLowerCase()) === 0)
	}

	render() {
		return (
			<Drawer open={this.props.isOpen} onClose={this.props.closeMainDrawer} >
				{this.renderItem(0, 'Apps', '/apps', <AppsIcon/>, true)}
				{this.renderItem(1, 'Users', `/users`, <UsersIcon/>, true)}
				<Divider/>
				{this.props.selectedApp && this.props.selectedApp.Links.map(this.renderMenuItems.bind(this))}
			</Drawer>
		)
	}

	renderItem = (index, name, path, icon, enabled) =>
		<MenuItem key={index}
		          disabled={!enabled}
		          onClick={this.handleLocationChange.bind(this, path)}
		          style={this.isCurrentPath('Apps') ? styles.rowActive : styles.rowInactive}
		          selected={this.isCurrentPath(name)}>
			<ListItemIcon>{icon}</ListItemIcon>
			<TextTitle>{name}</TextTitle>
		</MenuItem>

	renderMenuItems(row, index) {
		const {ID, CountryID} = this.props.selectedApp.Properties

		switch (row.Rel) {
			case 'update':
				return null
			case 'delete':
				return null
			case 'users':
				return null
			case 'plans':
				return null
			case 'progresses':
				return null
			case 'new game':
				return null
			case 'games':
				return null
			case 'subscriptions':
				return null
			case 'driving-schools':
				return null
			case 'exams':
				return null
			case 'statistics':
				return null
			case 'onboardings':
				return null
			case 'installations':
				return this.renderItem(index, 'Installations', `/country/${CountryID}/app/${ID}/installations`,
					<InstallationIcon/>, true)
			case 'sources':
				return this.renderItem(index, 'Sources', `/country/${CountryID}/app/${ID}/Sources`, <PhotoIcon/>, true)
			case 'payments':
				return this.renderItem(index, 'Payments', `/country/${CountryID}/app/${ID}/payments`,
					<PaymentsIcon/>, true)
			case 'roadsigns':
				return this.renderItem(index, 'RoadSigns', `/country/${CountryID}/app/${ID}/roadsigns`,
					<RoadSignIcon/>, true)
			case 'levels':
				return this.renderItem(index, 'Levels', `/country/${CountryID}/app/${ID}/levels`, <LevelsIcon/>, true)
			case 'theories':
				return this.renderItem(index, 'Theories', `/country/${CountryID}/app/${ID}/theories`,
					<TheoryIcon/>, true)
			case 'questions':
				return this.renderItem(index, 'Questions', `/country/${CountryID}/app/${ID}/questions`,
					<QuestionsIcon/>, true)
			case 'word-lists':
				return this.renderItem(index, 'Wordlist', `/country/${CountryID}/app/${ID}/wordlists`,
					<WordListIcon/>, true)
			case 'checklists':
				return this.renderItem(index, 'Checklist', `/country/${CountryID}/app/${ID}/checklists`,
					<ChecklistIcon/>, true)
			case 'qas':
				return this.renderItem(index, 'QAs', `/country/${CountryID}/app/${ID}/qas`, <QAIcon/>, true)
			case 'videos':
				return this.renderItem(index, 'Videos', `/country/${CountryID}/app/${ID}/videos`, <VideoIcon/>, true)
			case 'dog-breeds':
				return this.renderItem(index, 'Dog Breeds', `/country/${CountryID}/app/${ID}/dogbreeds`, <VideoIcon/>, true)
			default:
				return null
		}
	}
}

const styles = {
	rowActive: {
		color: '#fff',
		minWidth: 200,
	},
	rowInactive: {
		color: 'rgba(255, 255, 255, 0.58)',
		minWidth: 200,
	}
}

MainDrawer.propTypes = {
	closeMainDrawer: PropTypes.func,
	currPath: PropTypes.string,
	selectedApp: PropTypes.object
}

export default MainDrawer