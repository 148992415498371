import React from 'react'
import * as PropTypes from 'prop-types'
import {
	DialogContent,
	DialogTitle,
	DialogActions,
	Button, TextField
} from '@material-ui/core'

class IntegerUpdateDialog extends React.Component {
	constructor (props) {
		super(props)
		const {value = 0} = props
		this.state = {
			value
		}
	}

	render () {
		const {close, update, title, link} = this.props
		const {value} = this.state
		return (
			<React.Fragment>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<TextField
						value={this.state.value}
						fullWidth={true}
						required
						margin="normal"
						type="number"
						onChange={e => {
							this.setState({value: parseInt(e.target.value, 10)})
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={close} variant={'contained'} size='small'>
						Cancel
					</Button>
					<Button color="primary" onClick={() => update({[title]: value}, link)} variant={'contained'} size='small'>
						Update
					</Button>
				</DialogActions>
			</React.Fragment>
		)
	}
}

IntegerUpdateDialog.propTypes = {
	link: PropTypes.object,
	value: PropTypes.number,
	title: PropTypes.string,
	close: PropTypes.func,
	update: PropTypes.func,
}

export default IntegerUpdateDialog