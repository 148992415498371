import React from 'react'
import * as PropTypes from 'prop-types'

import TableComponent from '../table/TableComponent'

const sortOpts = [
	{
		name: 'Index',
		expr: 'Index'
	},
]

const ignoreAttrs = ['Deleted', 'Done', 'MoreInfo']

const RoadSigns = ({match: {params: {country_id, app_id}}, ...rest}) =>
	<TableComponent
		entityName={'roadsigns'}
		listSearchQuery={q => `ID:${q} OR Name:${q} OR Category:${q}`}
		to={id => `/country/${country_id}/app/${app_id}/roadsign/${id}`}
		downloadJsonUrl={`/Country/${country_id}/RoadSigns?full=true&latest=true`}
		csvUrl={`/Country/${country_id}/RoadSign/Csv`}
		versionUrl={`/Country/${country_id}/App/${app_id}/Version/RoadSign`}
		{...{ignoreAttrs, sortOpts}}
		{...rest}/>

RoadSigns.propTypes = {
	match: PropTypes.object.isRequired,
}

export default RoadSigns